import {
  commonTableProps,
  commonLineChartProps,
  PieChartOptions,
  nonSortableFields,
  linkFields,
} from "./common";
export const boldFields = ["Faculty Name​", "Certification Status"];
export const dateField = ["Registered on"];

export const NewFacilatorTransformer = ({ data, ...rest }) => {
  const colors = {
    Cummulative: "#CACACA",
    New: "#FFBC36",
  };
  return {
    ...commonLineChartProps,
    data: {
      labels: data.map((row) => row.Month),
      datasets: ["Cumulative", "New"].map((field, index) => ({
        label: field,
        data: data.map((row) => row[field]),
        backgroundColor: colors[field],
        borderColor: colors[field],
        yAxisID: index === 0 ? "y" : "y1",
        maxBarThickness: 50,
      })),
    },
    aggregations: rest?.aggregations
      ? Object.keys(rest.aggregations).map((field) => {
          return {
            text: field,
            value: rest?.aggregations[field],
          };
        })
      : [],
    filter: {
      handler: (value) => ({ filter: value }),
      values: [
        {
          text: "Last Month",
          value: "(1,0)",
        },
        {
          text: "Last 3 months",
          value: "(3,0)",
        },
      ],
    },
  };
};

export const NewFacilatorByCollegeTransformer = ({ data }) => {
  return {
    ...commonTableProps(data),
    filters: [
      {
        name: "A-Z",
        handler: () => ({ filter: "Actual" }),
        isSelected: (currentFilter) => currentFilter === "Actual",
      },
      // {
      // 	name: 'Complete',
      // 	handler: () => ({ filter: 'Expected' }),
      // 	isSelected: (currentFilter) => currentFilter === 'Expected',
      // },
      // {
      // 	name: 'Much less than expected',
      // 	handler: () => ({ filter: 'Much less than expected' }),
      // 	isSelected: (currentFilter) => currentFilter === 'Much less than expected',
      // }
    ],
    heading: "College-wise Facilitator Registrations",
    viewAllLink: "View all colleges",
  };
};

export const TrainedNotTrainedByCollegeTransformer = ({ data }) => {
  return {
    ...commonTableProps(data),
    filters: [
      {
        name: "A-Z",
        handler: () => ({ filter: "Actual" }),
        isSelected: (currentFilter) => currentFilter === "Actual",
      },
      {
        name: "Complete",
        handler: () => ({ filter: "Complete" }),
        isSelected: (currentFilter) => currentFilter === "Complete",
      },
      {
        name: "Mostly not trained",
        handler: () => ({ filter: "Mostly not trained" }),
        isSelected: (currentFilter) => currentFilter === "Mostly not trained",
      },
    ],
    heading: "College-wise Facilitators Trained/ Not trained",
    viewAllLink: "View all colleges",
  };
};

export const TrainedNotTrainedTransformer = ({ data }) => {
  const colors = ["#949820", "#FFBC36"];
  return {
    ...PieChartOptions,
    data: {
      labels: data.map((row) => row["Category"]),
      datasets: [
        {
          label: "Training Percentage",
          data: data.map((row) => row["Data"]),
          backgroundColor: colors,
          borderColor: colors,
          borderWidth: 1,
        },
      ],
    },
    heading: "Proportion of Trained/ Not Trained Faculty (Module-wise)",
  };
};

export const FacilatorWiseCertificationTransformer = ({ data }) => {
  return {
    columns:
      data.length > 0
        ? Object.keys(data[0]).map((row) => {
            return {
              field: row,
              headerName: row,
              sortable: !nonSortableFields.includes(row),
              link: {
                isLink: linkFields.includes(row) && data[0][row] !== "N/A",
                linkText: "View Report",
              },
            };
          })
        : [],
    rows: data,
    perPage: 5,
    filters: [
      {
        name: "All",
        handler: () => ({ filter: "All" }),
        isSelected: (currentFilter) => currentFilter === "All",
      },
      {
        name: "Completed",
        handler: () => ({ filter: "Completed" }),
        isSelected: (currentFilter) => currentFilter === "Completed",
      },
      {
        name: "Not Completed",
        handler: () => ({ filter: "Not Completed" }),
        isSelected: (currentFilter) => currentFilter === "Not Completed",
      },
    ],
    heading: "Facilitator-wise Certification",
    viewAllLink: "View all faculty",
    boldFields,
    dateField,
  };
};
