export const nonSortableFields = ["Report"];
export const linkFields = ["Report"];

export const commonTableProps = (rows) => ({
  columns:
    rows?.length > 0
      ? Object.keys(rows[0]).map((row) => {
          return {
            field: row,
            headerName: row,
            sortable: !nonSortableFields.includes(row),
          };
        })
      : [],
  rows: rows || [],
  perPage: 5,
});

export const LineChartOptions = {
  scales: {
    y: {
      ticks: {
        beginAtZero: true,
      },
      type: "linear",
      position: "left",
      display: true,
      title: {
        display: true,
        text: "Cumalatative",
      },
    },
    x: {},
    y1: {
      type: "linear",
      display: true,
      position: "right",

      // grid line settings
      grid: {
        drawOnChartArea: false, // only want the grid lines for one axis to show up
      },
      title: {
        display: true,
        text: "New",
      },
    },
  },
  plugins: {
    legend: {
      position: "bottom",
      align: "start",
      // maxHeight: 15
      labels: {
        boxHeight: 2,
        boxWidth: 25,
      },
    },
  },
};

export const BarChartOptions = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
  plugins: {
    legend: {
      position: "bottom",
      align: "start",
      maxSize: {
        height: 10,
      },
    },
  },
};

export const StackedBarChartOptions = {
  scales: {
    y: {
      stacked: true,
    },
    x: {
      stacked: true,
    },
    y1: {
      position: "right",
    },
  },
  plugins: {
    legend: {
      position: "bottom",
      align: "start",
    },
  },
};

export const commonLineChartProps = {
  filter: {
    handler: (value) => ({ filter: value }),
    values: [
      {
        text: "Last Month",
        value: "(1,0)",
      },
      {
        text: "Last 3 months",
        value: "(3,0)",
      },
      {
        text: "Last Year",
        value: "(0,1)",
      },
    ],
  },
  initialParam: { filter: "(3,0)" },
  options: StackedBarChartOptions,
};

export const PieChartOptions = {
  options: {
    legend: {
      position: "bottom",
      align: "middle",
    },
  },
};

export const MixedChartOptions = {
  scales: {
    y: {
      ticks: {
        beginAtZero: true,
      },
    },
    x: {
      grid: {
        color: "transparent",
      },
      ticks: {
        autoSkip: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};
