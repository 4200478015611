import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const PieChart = ({ data, options, heading, description }) => {

	return (<div className="w-100 h-100 d-flex flex-column">
		<div className="row">
			<div className="col-12 col-md-4 pe-2 pe-md-5">
				<span className="font-18 mb-4 d-block">{ heading }</span>
				<p>{ description }</p>
			</div>
			<div className="col-12 col-md-8">
				<Bar data={data} options={options} />
			</div>
		</div>
	</div>);
};

export default PieChart;