import { commonTableProps, commonLineChartProps } from './common';

export const NewStudentRegistrationTransformer = ({ data, ...rest }) => {
	const colors = {
		Cummulative: "#CACACA",
		New: "#FFBC36",
	};
	return {
		...commonLineChartProps,
		data: {
			labels: data.map(row => row.Month),
			datasets: ["Cumulative", "New"].map((field, index) => ({
				label: field,
				data: data.map(row => row[field]),
				backgroundColor: colors[field],
				borderColor: colors[field],
				yAxisID: index===0 ? 'y' : 'y1'
			})),
		},
		aggregations: rest?.aggregations ? Object.keys(rest.aggregations).map(field => {
			return {
				text: field,
				value: rest?.aggregations[field],
			};
		}) : [],
	};
};

export const NewStudentRegistrationByCollegeTransformer = ({ data }) => {

	return {
		...commonTableProps(data),
		filters: [
			{
				name: 'A-Z',
				handler: () => ({ filter: 'Actual' }),
				isSelected: (currentFilter) => currentFilter === 'Actual',
			},
			{
				name: 'Complete',
				handler: () => ({ filter: 'Expected' }),
				isSelected: (currentFilter) => currentFilter === 'Expected',
			},
			{
				name: 'Much less than expected',
				handler: () => ({ filter: 'Much less than expected' }),
				isSelected: (currentFilter) => currentFilter === 'Much less than expected',
			}
		],
		heading: 'College-wise Student Registrations',
		viewAllLink: 'View all colleges',
	};
};