import React, { useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import App from "./App";
import AppProvider from "./providers/app.provider";
import NetworkProvider from "./providers/network.provider";
import AxiosClient from "./utils/axios";
import NetworkHandler from "./components/partials/NetworkHandler";
import Alert from "./components/partials/alert";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { ClientProvider } from "./store/ClientProvider";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFBC36",
    },
    secondary: {
      main: "#000",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontFamily: "'Fira Sans', sans-serif",
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: "8px 16px",
      },
      content: {
        margin: "0 !important",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "black !important",
      },
    },
    MuiTableRow: {
      head: {
        backgroundColor: "#F5F5F5",
      },
    },
    MuiTableCell: {
      root: {
        fontFamily: "'Fira Sans', sans-serif",
        fontSize: "1.1rem",
      },
      head: {
        color: "#000",
        fontWeight: "bold",
      },
      body: {
        padding: "1.2rem",
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "12px 25px",
      },
      notchedOutline: {
        borderColor: "#eee",
      },
    },
    MuiIconButton: {
      label: {
        color: "inherit",
      },
    },
    MuiSelect: {
      outlined: {
        paddingRight: "48px !important",
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AppProvider>
          <NetworkProvider>
            <AxiosClient />
            <NetworkHandler />
            <Alert />
            <ClientProvider>
              <App />
            </ClientProvider>
          </NetworkProvider>
        </AppProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
