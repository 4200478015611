import axios from "axios";
import qs from "qs";
import {
  LOGIN_URL,
  REGISTER_URL,
  GET_USER_INFO_URL,
  FORGOT_PASSWORD_URL,
  RESET_PASSWORD_URL,
  GET_ORGANIZATION_INFO,
  MASTER_TRAINER_URL,
  PATCH_USER_FIRSTTIME_URL,
  LINE_GRAPH_URL,
} from "../constants/urls";

const buildUrlParams = (url, params) => {
  return `${url}?${qs.stringify(params)}`;
};

export const login = (payload) => axios.post(LOGIN_URL, payload);

export const register = (payload) => axios.post(REGISTER_URL, payload);

export const fetchUserInfo = () => axios.get(GET_USER_INFO_URL);

export const forgetPassword = (payload) =>
  axios.post(FORGOT_PASSWORD_URL, payload);

export const resetPassword = (payload) =>
  axios.post(RESET_PASSWORD_URL, payload);

export const fetchOrganizationInfo = (orgId) =>
  axios.get(`${GET_ORGANIZATION_INFO}/${orgId}`);

export const fetchMasterTrainer = (payload, url) =>
  axios.get(buildUrlParams(url, payload));

export const patchUserFirstTimeUser = (userId, payload) =>
  axios.patch(`${PATCH_USER_FIRSTTIME_URL}/${userId}`, payload);

export const lineGraphData = () => axios.get(LINE_GRAPH_URL);
