import React from "react";
import { Bar, Chart } from "react-chartjs-2";

const BarChart = ({ datasetData, labelData }) => {
  console.log("propsInBar", datasetData);
  console.log("propsInLabel", labelData);
  const Program_Average = datasetData[0]?.Program_Average;
  const dataTable = datasetData[0]?.data;
  console.log("Program_Average", Program_Average);
  console.log("dataTable", dataTable);
  const data = {
    labels: labelData,
    datasets: [
      {
        label: "Average Data",
        data: dataTable,
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
        hidden: false,
      },
    ],
  };

  const values = data?.datasets[0]?.data;
  const average =
    values?.reduce((acc, value) => acc + value, 0) / values?.length;

  data?.datasets[0]?.data?.forEach((value) => {
    if (value >= Program_Average) {
      data.datasets[0].backgroundColor.push("rgba(0, 255, 0, 0.2)"); // Green
      data.datasets[0].borderColor.push("rgba(0, 255, 0, 1)");
    } else if (value >= 60 && value <= 80) {
      data.datasets[0].backgroundColor.push("rgba(255, 255, 0, 0.2)"); // Yellow
      data.datasets[0].borderColor.push("rgba(255, 255, 0, 1)");
    } else {
      data.datasets[0].backgroundColor.push("rgba(255, 99, 132, 0.2)"); // Red
      data.datasets[0].borderColor.push("rgba(255, 99, 132, 1)");
    }
  });

  const options = {
    indexAxis: "y",
    layout: {
      padding: {
        right: 120, // Increase the right padding to widen the chart
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      x: {
        beginAtZero: true,
        grid: {
          borderColor: "rgba(0, 0, 0, 0.1)",
          borderWidth: 1,
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          // Hide the top label in the legend
          filter: (item) => !item.text.includes("Average Data"),
        },
      },
      customLines: {
        vertical: [
          {
            x: Program_Average,
            borderColor: "rgba(0, 0, 0, 0.9)",
            borderDash: [5, 5],
            label: {
              content: `Program Average: ${Program_Average?.toFixed(2)}%`,
              enabled: true,
              position: "bottom",
            },
          },
        ],
      },
    },
  };
  const drawLinesPlugin = {
    id: "drawLines",
    afterDraw: (chart) => {
      const line = chart.options.plugins.customLines.vertical[0];
      const xScale = chart.scales.x;
      const yScale = chart.scales.y;
      const ctx = chart.ctx;

      if (line) {
        const x = xScale.getPixelForValue(line.x);
        ctx.save();
        ctx.beginPath();
        ctx.strokeStyle = line.borderColor;
        ctx.lineWidth = 2; // Make the line bolder
        ctx.setLineDash(line.borderDash || []);
        ctx.moveTo(x, chart.chartArea.top);
        ctx.lineTo(x, chart.chartArea.bottom);
        ctx.stroke();
        ctx.restore();

        ctx.fillStyle = "rgba(0, 0, 0, 0.8)";
        ctx.font = "12px Arial";
        // ctx.fillText(line.label.content, x + 10, chart.chartArea.bottom - 10);
        const textWidth = ctx.measureText(line.label.content).width;
        const newX = x + 10; // Adjust this value as needed

        ctx.fillText(line.label.content, newX, chart.chartArea.bottom - 10);
      }
    },
  };

  Chart.register(drawLinesPlugin);

  return (
    <div style={{ width: "80%", marginBottom: "10%" }}>
      <Bar data={data} options={options} />
      <h1 style={{ textAlign: "center" }}>% Average Score</h1>
    </div>
  );
};

export default BarChart;
