import React from "react";

const FlashPointsAnalytics = () => {
  return (
    <div>
      <h1>hello</h1>
    </div>
  );
};

export default FlashPointsAnalytics;
