import { LineChart, Table, BarChart, PieChart, MixedChart } from "./charts";
import {
  NetCollegeRegistrationTransformer,
  NewCollegeRegistrationByDistrictTransformer,
} from "./transformers/colleges";
import {
  NewFacilatorTransformer,
  TrainedNotTrainedByCollegeTransformer,
  NewFacilatorByCollegeTransformer,
  TrainedNotTrainedTransformer,
  FacilatorWiseCertificationTransformer,
} from "./transformers/facilitors";
import {
  NewStudentRegistrationTransformer,
  NewStudentRegistrationByCollegeTransformer,
} from "./transformers/student";
import {
  NewSessionTransformer,
  ModuleWiseSessionTransformer,
  FacilatorWiseSessionPerformaceTransformer,
  ExpectedActualSessions,
  FacilatorPreparationSessions,
  EngagementIndexTransformer,
  PreferedLanguageTransformer,
} from "./transformers/sessions";
import {
  StudentResourceUsageTransformer,
  FacilitatorResourceUsageTransformer,
} from "./transformers/resource";

export const widgetMap = {
  NewCollegeRegistration: {
    component: BarChart,
    transformer: NetCollegeRegistrationTransformer,
  },
  NewCollegeRegistrationByDistrict: {
    component: Table,
    transformer: NewCollegeRegistrationByDistrictTransformer,
  },
  NewFacilator: {
    component: BarChart,
    transformer: NewFacilatorTransformer,
  },
  NewFacilaatorByCollege: {
    component: Table,
    transformer: NewFacilatorByCollegeTransformer,
  },
  TrainedNotTrainedByCollege: {
    component: Table,
    transformer: TrainedNotTrainedByCollegeTransformer,
  },
  FacilatorWiseCertification: {
    component: Table,
    transformer: FacilatorWiseCertificationTransformer,
  },
  NewStudent: {
    component: LineChart,
    transformer: NewStudentRegistrationTransformer,
  },
  NewStudentByCollege: {
    component: Table,
    transformer: NewStudentRegistrationByCollegeTransformer,
  },
  NewSessions: {
    component: LineChart,
    transformer: NewSessionTransformer,
  },
  SessionExpectedVsActial: {
    component: BarChart,
    transformer: ExpectedActualSessions,
  },
  ModuleWiseSession: {
    component: Table,
    transformer: ModuleWiseSessionTransformer,
  },
  FacilatorPreparation: {
    component: BarChart,
    transformer: FacilatorPreparationSessions,
  },
  FacilatorWisePerformance: {
    component: Table,
    transformer: FacilatorWiseSessionPerformaceTransformer,
  },
  StudentResourceUsage: {
    component: Table,
    transformer: StudentResourceUsageTransformer,
  },
  FacilatorResourceUsage: {
    component: Table,
    transformer: FacilitatorResourceUsageTransformer,
  },
  TrainedNotTrained: {
    component: PieChart,
    transformer: TrainedNotTrainedTransformer,
  },
  EngagementIndex: {
    component: MixedChart,
    transformer: EngagementIndexTransformer,
  },
  PreferedLanguage: {
    component: PieChart,
    transformer: PreferedLanguageTransformer,
  },
};
