import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  // MuiTableCell,
} from "@material-ui/core";

import CustomTablehead from "./tableHead";

import "./styles.scss";

function descendingComparator(a, b, orderBy) {
  if (typeof a === "string") {
    if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
      return -1;
    }
    if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  let sorting = JSON.parse(JSON.stringify(stabilizedThis.map((el) => el[0])));
  return sorting;
}
function CustomTable(props) {
  const {
    rows,
    headCells,
    ignoreColumns,
    emptyColumn,
    emptyCharacter,
    defaultOrder,
    defaultOrderBy,
    ref,
    percentageColumn,
    grandTotalColumns,
    clickable,
    showColor,
    open,
    setOpen,
    boldColumn,
    leftAlignColumn,
    showRowsPerPage,
    disableSortColumn,
    columnWidth,
    emptyInfo,
    htmlColumns,
    ...rest
  } = props;
  const [order, setOrder] = React.useState(defaultOrder ? defaultOrder : "asc");
  const [orderBy, setOrderBy] = React.useState(
    defaultOrderBy ? defaultOrderBy : ""
  );
  const [selected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(showRowsPerPage || 100);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // console.log("grandTotalColumns", grandTotalColumns);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const calculateTimeDifference = (str) => {
    const [startTime, endTime] = str?.split("and").map((time) => time.trim());
    const [startHours, startMinutes] = startTime?.split(":").map(Number);
    const [endHours, endMinutes] = endTime?.split(":").map(Number);

    const totalStartMinutes = startHours * 60 + startMinutes;
    const totalEndMinutes = endHours * 60 + endMinutes;

    const minuteDifference = Math.abs(totalEndMinutes - totalStartMinutes);
    const hourDifference = Math.floor(minuteDifference / 60);

    // Check if the first time is greater than the second time and spans different days
    if (totalStartMinutes >= totalEndMinutes && hourDifference <= 4) {
      return false;
    }

    // Check if the time difference is less than or equal to 4 hours
    if (hourDifference <= 4) {
      return true;
    }

    return false; // Return false for other cases
  };

  const checkSingleChar = (str) => {
    // console.log("str in pairing", str);
    const validPairs = ["AD", "DA", "BD", "DB", "CD", "DC", "DD"];
    if (str.length === 1 || validPairs.includes(str)) {
      // console.log("inside", str);
      return true;
    }
    return false;
  };

  const checkBatchSize = (str) => {
    const batchSize = parseInt(str);
    if (batchSize > 34 || batchSize < 25) {
      return true;
    }
    return false;
  };
  const checkAssessmentValue = (assessment, totalValue) => {
    // console.log("assessment", assessment);
    // console.log("totalValue", totalValue);
    if (typeof assessment === "string" && assessment.length > 0) {
      const assessmentValues =
        (assessment && assessment.split("/").map((val) => parseInt(val))) || [];
      const newValue = parseInt(totalValue);

      const isAnyValueLessThan80Percent = assessmentValues.some(
        (val) => val < newValue * 0.8
      );
      return isAnyValueLessThan80Percent;
    }
  };
  // const result = checkAssessmentValue("22/30/30", "30");
  // console.log("result", result);
  const checkIfRatiosIsLow = (str, ogKey, keysToCompare) => {
    // console.log("str", str);
    if (typeof str === "string") {
      let newBatch = str?.split("/");
      let bool;
      if (parseInt(newBatch[0]) < parseInt(newBatch[1])) {
        bool = true;
      } else {
        bool = false;
      }
      return bool && ogKey == keysToCompare;
    }
  };
  const showWarning = (data, ogKey, row) => {
    switch (ogKey) {
      case "Assessment Completion": {
        return checkIfRatiosIsLow(data, ogKey, "Assessment Completion");
      }
      case "MT Pair Grade": {
        return checkSingleChar(data);
      }
      case "Class Engagement": {
        return checkAssessmentValue(data, row["Batch Size"]);
      }
      case "Start and End Time": {
        return calculateTimeDifference(data);
      }
      case "Batch Size": {
        return checkBatchSize(data);
      }
      default:
        return false;
    }
  };
  // console.log("grandTotalColumns", grandTotalColumns);
  const getCellContent = (row, head) => {
    // console.log("head", head);
    // console.log("rowInTable", row);
    let type = typeof JSON.parse(JSON.stringify(row[head.originalKey]));
    // let newRow = JSON.parse(JSON.stringify(row[head.originalKey]));
    // console.log("type", type);
    // console.log("newRow", typeof newRow[head.originalKey]);
    // console.log("row[head.originalKey]", typeof row[head.originalKey]);
    // console.log("newRowAfterRender", newRow);

    // console.log(
    //   "firstROw",
    //   typeof JSON.parse(JSON.stringify(row[head.originalKey]))
    // );
    // console.log(typeof newRow[head.originalKey] === "object");

    if (type !== "object")
      return (
        <div
          style={
            showColor?.[head.originalKey]
              ? {
                  background:
                    showColor[head.originalKey][row?.[head.originalKey]],
                  width: "24px",
                  height: "24px",
                  borderRadius: "50%",
                }
              : boldColumn?.includes(head.originalKey)
              ? {}
              : {}
          }
        >
          {showWarning(row[head.originalKey], head.originalKey, row) ? (
            <strong style={{ color: "red", marginRight: "10px" }}>!</strong>
          ) : (
            <></>
          )}
          {emptyColumn?.includes(head.originalKey) &&
          row[head.originalKey] === emptyInfo
            ? emptyCharacter
            : showColor?.[head.originalKey]?.[row?.[head.originalKey]]
            ? emptyCharacter
            : row[head.originalKey]}
          {percentageColumn?.includes(head.originalKey) &&
          row[head.originalKey] !== emptyInfo
            ? "%"
            : ""}
        </div>
      );
    else {
      // let html = `<div>${row[head.originalKey]}</div>`;
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // gap: "rem",
            height: "550px",
            alignItems: "center",
            justifyContent: "space-around",
            marginBottom: "10px",
          }}
        >
          {row?.[head?.originalKey]?.length &&
            row?.[head?.originalKey]?.map((data) => {
              return <div>{data}</div>;
            })}
        </div>
      );
    }
  };

  return (
    <div className="custom-table-wrapper">
      <TablePagination
        rowsPerPageOptions={[25, 50, 75, 100]}
        component="div"
        count={rows?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <TableContainer
        {...rest}
        className="table-div"
        style={{
          height: "max-content",
          maxHeight: "620px",
          width: "100%",
          background: "#FFFFFF",
        }}
      >
        <div style={{}}>
          <Table
            stickyHeader
            sx={{ minWidth: 750, top: "0px" }}
            aria-labelledby="tableTitle"
            // ref={ref}
            className="custom-table"
          >
            <CustomTablehead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length || 1}
              headCells={headCells}
              ignoreHeads={ignoreColumns}
              showColor={showColor}
              boldColumn={boldColumn}
              disableSortColumn={disableSortColumn || []}
              columnWidth={columnWidth || {}}
            />
            <TableBody>
              {stableSort(rows || [], getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={() => {}}
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${index}`}
                      selected={isItemSelected}
                    >
                      {headCells
                        .filter(
                          (headCell) => !ignoreColumns.includes(headCell.label)
                        )
                        .map((head, hIndex) => (
                          <TableCell
                            key={hIndex}
                            style={{
                              color: "#3F3F3F",
                              lineHeight: "1.3",
                              textAlign: head.numeric ? "left" : "center",
                              fontStyle: hIndex == 0 ? "bold" : "",
                            }}
                          >
                            <div
                              // className="height_100"
                              style={
                                showColor?.[head.originalKey]?.[
                                  row?.[head.originalKey]
                                ]
                                  ? {
                                      width: "fit-content",
                                      border: "2px solid #EBDEDE",
                                      padding: "5%",
                                      borderRadius: "50%",
                                      // marginRight: "25%",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                    }
                                  : {
                                      fontWeight: hIndex == 0 ? "bold" : "",
                                      width: showColor?.[head.originalKey]?.[
                                        row?.[head.originalKey]
                                      ]
                                        ? "fit-content"
                                        : columnWidth?.[head.label],
                                      marginRight: showColor?.[
                                        head.originalKey
                                      ]?.[row?.[head.originalKey]]
                                        ? "45%"
                                        : head.numeric
                                        ? "0"
                                        : "25%",
                                    }
                              }
                            >
                              {getCellContent(row, head)}
                            </div>
                          </TableCell>
                        ))}
                    </TableRow>
                  );
                })}
              {grandTotalColumns?.length > 0 && (
                <TableRow
                  hover
                  onClick={(event) => {}}
                  tabIndex={-1}
                  className="custom-table-head"
                >
                  <TableCell
                    style={{
                      color: "#3F3F3F",
                      fontWeight: "bold",
                      lineHeight: "1",
                    }}
                  >
                    Total
                  </TableCell>
                  {headCells.map((cell, index) =>
                    index !== 0 ? (
                      <TableCell
                        style={{
                          color: "#3F3F3F",
                          fontWeight: "bold",
                          lineHeight: "1",
                          textAlign: "center",
                        }}
                      >
                        {grandTotalColumns?.includes(cell.originalKey)
                          ? rows.reduce((total, currentValue) => {
                              return (
                                total +
                                (currentValue[cell.originalKey] != ""
                                  ? parseInt(currentValue[cell.originalKey], 10)
                                  : 0)
                              );
                            }, 0)
                          : ""}
                      </TableCell>
                    ) : undefined
                  )}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </TableContainer>
    </div>
  );
}

CustomTable.propTypes = {
  ignoreColumns: PropTypes.arrayOf(PropTypes.string),
};

CustomTable.defaultProps = {
  ignoreColumns: [],
};

export default CustomTable;
