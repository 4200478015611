import React, { useEffect, useContext, useState, useMemo } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  Button,
  IconButton,
  ButtonGroup,
} from "@material-ui/core";
import { Info, ArrowRight, ChevronRight } from "@material-ui/icons";
import { Link } from "react-router-dom";

import { useLocation } from "react-router";
import { toUrl, toReplace } from "../../utils/common";
import "./styles.scss";
import {
  fetchMasterTrainer,
  fetchUserInfo,
  lineGraphData,
} from "../../services/auth";
import CustomTable from "../partials/table";
import CustomModal from "../partials/modal";
import createDOMPurify from "dompurify";
import _ from "lodash";
import Download from "../dashboard/details/Download";
import { AppStateContext } from "../../providers/app.provider";

import axios from "axios";

import qs from "qs";
import BarChart from "../charts/BarChart";
import LineChart from "../charts/LineChart";
import { useClientContext } from "../../store/ClientProvider";
const DOMPurify = createDOMPurify(window);

const buildUrlParams = (url, params) => {
  return `${url}?${qs.stringify(params)}`;
};
function MasterTrainer(props) {
  const { showInfo, showFilters, url, periods, ...pageConfig } = props;
  console.log("url", url);
  console.log("props", { props });
  const { snapshotGraph } = props;
  // console.log("snapshotGraph", snapshotGraph);
  // console.log("snapshotGraph", snapshotGraph.type);
  const location = useLocation();
  const { client, updateClient } = useClientContext();

  const [, setHeading] = useState("");
  const [summary, setSummary] = useState([]);
  const [headCells, setHeadCells] = useState([]);
  const [open, setOpen] = useState(false);
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);

  const [whatShow, setWhatShow] = useState(false);
  const [, setFieldStaff] = useState(null);
  const [snapshot, setSnapshot] = useState(null);

  const [college, setCollege] = useState("");
  const [colleges, setColleges] = useState([]);

  const [division, setDivision] = useState("");
  const [divisions, setDivisions] = useState([]);

  const [region, setRegion] = useState("");
  const [regions, setRegions] = useState([]);

  const [faculty, setFaculty] = useState("");
  const [facultys, setFacultys] = useState([]);

  const [batches, setBatches] = useState([]);
  const [batch, setBatch] = useState("");

  const [districts, setDistricts] = useState([]);
  const [district, setDistrict] = useState("");
  const [policeStation, setPoliceStation] = useState("");
  const [policeStations, setPoliceStations] = useState([]);

  const [state, setState] = useState("");
  const [states, setStates] = useState([]);

  const [period, setPeriod] = useState(periods?.[0]?.value || "level");
  const [collectionname] = useState(props?.collectionname);
  const [report] = useState(props?.report);

  const [accesscode, setAccesscode] = useState("");
  const [collegename, setCollegename] = useState("");
  // const [client, setClient] = useState("CSC");
  const [clientData] = useState(props?.client);

  const [lineLabels, setLineLabels] = useState([]);
  const [lineDatasets, setLineDatasets] = useState([]);

  const [trainer, setTrainer] = useState("");
  const [trainers, setTrainers] = useState([]);

  const [showInfoData, setShowInfoData] = useState(true);
  const [selectedButton, setSelectedButton] = useState("");

  const handleButtonClick = (buttonName) => {
    if (buttonName === selectedButton) {
      setSelectedButton("");
      setShowInfoData(false);
    } else {
      setSelectedButton(buttonName);
      setShowInfoData(true);
    }
  };

  const organizationName = pageConfig?.organisationName;

  const getUserInfo = async () => {
    return fetchUserInfo().then((data) => {
      setAccesscode(data.data.accesscode);
      let col = data.data.meta?.bput?.college_code?.split(/[,]+/);
      let newCol = col?.[0]?.replace(/ /g, "+");
      setCollegename(newCol);
    });
  };

  const getLineGraphData = async () => {
    const results = await lineGraphData();
    // console.log("resultsLineGraph", results?.data);
    let lineLabel = results?.data?.labels;
    let lineDataset = results?.data?.datasets;
    // console.log("lineLabel", lineLabel);
    setLineLabels(lineLabel);
    setLineDatasets(lineDataset);
  };
  // getLineGraphData();

  useEffect(async () => {
    await getLineGraphData();
    if (organizationName == "BPUT") {
      await getUserInfo();
      if (location.hash && collegename && accesscode) {
        setHeading(location.hash);
        const payload = {
          view: "summary",
          period,
          collectionname,
          accesscode,
          collegename,
          report,
          client: client,
        };
        // // let url = "https://uatapi.pedgog.in/v1/cscanalytics/getanalysisdata";
        // let url = "http://localhost:5000/v1/cscanalytics/getanalysisdata";

        getMasterTrainer(payload, url);
      }
    } else {
      if (location.hash) {
        // console.log("useeffect");
        setHeading(location.hash);

        const payload = {
          view: "summary",
          period,
          collectionname,
          accesscode,
          collegename,
          report,
          client: client,
        };
        // let url = "https://uatapi.pedgog.in/v1/cscanalytics/getanalysisdata";

        // let url = "http://localhost:5000/v1/cscanalytics/getanalysisdata";

        getMasterTrainer(payload, url);
      }
    }
  }, [location.hash, period, collegename, client]);

  const user = JSON.parse(localStorage.getItem("user"));
  const splitKey = (key) => {
    const _key = (user.organizationid = "6123add4dfd0fbe63095d7c7"
      ? key.split("  ")
      : key.split("("));
    const keys = {
      id: _key[0],
      // id: _key[0].trim().toLowerCase().replace(/ /g, "_"),
      label: _key[0].trim(),
      caption: _key[1] ? "(".concat(_key[1]) : null,
    };

    return keys;
  };

  const processHead = (arr, sum) => {
    let arr2 = [];
    const heads1 = [sum].map((k) => {
      let arr1 = [];
      for (let i = 0; i < Object.keys(k).length; i++) {
        arr1 = Object.assign(
          {
            originalKey: Object.keys(k)[i],
            numeric: isNaN(Object.values(k)[i]),
            disablePadding: false,
          },
          splitKey(Object.keys(k)[i])
        );
        arr2.push(arr1);
      }
    });
    setHeadCells(arr2);
  };
  // console.log("trainer", trainer);
  const handleChange = (e, item) => {
    const value = e.target.value;
    switch (item) {
      case "faculty":
        setFaculty(value);
        break;
      case "college":
        setCollege(value);
        break;
      case "batch":
        setBatch(value);
        break;
      case "region":
        setRegion(value);
        break;
      case "division":
        setDivision(value);
        break;
      case "district":
        setDistrict(value);
        break;
      case "policeStation":
        setPoliceStation(value);
        break;
      case "state":
        setState(value);
        break;
      case "trainer":
        setTrainer(value);
        break;
      default:
        break;
    }
  };
  const fetchMasterTrainerNew = (payload, url) =>
    axios.get(buildUrlParams(url, payload));
  const getMasterTrainer = (payload, URL) => {
    fetchMasterTrainer(
      payload,
      url
      // "https://uatapi.pedgog.in/v1/analytics/cscanalytics/getanalysisdata"
      // "http://localhost:5000/v1/cscanalytics/getanalysisdata"
    )
      .then((data) => {
        // console.log("dataInThen", data);
        if (typeof data.data !== "object") data.data = JSON.parse(data.data);
        // console.log("data.data", data.data.labels);
        const _labels = data.data.labels;
        const _datasets = data.data.datasets;
        const _summary = data.data.data;
        const fieldStaff = data.data.field_staff;
        const _snapshot = data.data.snapshot;

        const _batches = _.filter(
          _.uniq(_.map(_summary, (_s) => _s.Batch)),
          (b) => b
        ).sort();
        const _facultys = _.filter(
          _.uniq(_.map(_summary, (_s) => _s["Faculty Name"])),
          (f) => f
        );
        const _colleges = _.filter(
          _.uniq(_.map(_summary, (_s) => _s.College)),
          (d) => d
        );
        const _divisions = _.filter(
          _.uniq(_.map(_summary, (_s) => _s.Division)),
          (d) => d
        );
        const _regions = _.filter(
          _.uniq(_.map(_summary, (_s) => _s.Region)),
          (d) => d
        );
        const _districts = _.filter(
          _.uniq(_.map(_summary, (_s) => _s["District/Units"])),

          (d) => d
        );
        const _policeStation = _.filter(
          _.uniq(_.map(_summary, (_s) => _s["Police Station"])),
          (d) => d
        );
        const _state = _.filter(
          _.uniq(_.map(_summary, (_s) => _s.State)),
          (d) => d
        );
        const _trainer = _.filter(
          _.uniq(_.map(_summary, (_s) => _s.Trainer)),
          (d) => d
        );
        // console.log("_trainer", _trainer);
        setTrainers(_trainer);
        setStates(_state);

        setLabels(_labels);
        setDatasets(_datasets);

        setRegions(_regions);
        setDivisions(_divisions);

        setPoliceStations(_policeStation);
        setDistricts(_districts);

        setSummary(data.data.data);
        setFieldStaff(fieldStaff);
        setSnapshot(_snapshot);
        setBatches(_batches);
        setColleges(_colleges);
        setFacultys(_facultys);
        if (_summary?.length > 0) {
          processHead(Object.keys(_summary[0]), _summary[0]);
        }
      })
      .catch((err) => {
        setWhatShow(true);
        console.error(err);
      });
  };
  // console.log("labels", labels);
  // console.log("datasets", datasets);
  // console.log("helloImLabel", labels && datasets);
  // console.log("state", state);
  // console.log("states", states);
  // console.log("location.pathname", location.pathname);
  // console.log("location.hash", location.hash);
  const viewAllArgs = useMemo(() => {
    return {
      pathname: toUrl(
        `${location.pathname}/details/widgetname${location.hash}`
      ),
    };
  }, []);

  console.log("snapshot ==> ", snapshot);

  const filter = (rows) => {
    // console.log("state", state);
    // console.log("rowsInMasterTable", rows);
    let _filter = {
      College: college === "" ? undefined : college,
      Division: division === "" ? undefined : division,
      Region: region === "" ? undefined : region,
      ["Faculty Name"]: faculty === "" ? undefined : faculty,
      Batch: batch === "" ? undefined : batch,
      ["District/Units"]: district === "" ? undefined : district,
      ["Police Station"]: policeStation === "" ? undefined : policeStation,
      State: state === "" ? undefined : state,
    };
    _filter = _.omitBy(_filter, _.isUndefined);
    return _.filter(rows, _filter);
  };
  function retFun() {
    // if (snapshot == null && whatShow) {
    if (snapshot == null && whatShow) {
      return (
        <div
          style={{
            position: "relative",
            top: "180px",
            textAlign: "center",
            fontWeight: 500,
            fontFamily: "'Fira Sans', sans-serif",
          }}
        >
          {props?.message}
          {/* <Spinner />
          {whatShow ? "data nehi hai tumara" : ""} */}
        </div>
      );
    } else if (snapshot || labels || datasets != null) {
      return (
        <div>
          <section className="section master-trainer">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div className="heading-row mb-4" style={{ width: "78%" }}>
                <div className="heading">
                  {(pageConfig && pageConfig.heading) || ""}
                  <IconButton
                    onClick={() => setOpen(true)}
                    style={{ padding: "0.6%" }}
                  >
                    <Info />
                  </IconButton>
                </div>
                <div className="sub-heading ">
                  {/* {(pageConfig && pageConfig?.subHeading) || "No Sub Heading Defined"} */}
                  {pageConfig && pageConfig?.subHeading}
                </div>
                {/* <div className="close-btn-w">
        <Link
          variant="contained"
          color="primary"
          to={viewAllArgs}
          className="close-btn"
        >
          View Details <ArrowForward />
        </Link>
      </div> */}
              </div>

              <div
                className="view-details"
                style={{ marginBottom: "4%", width: "35%" }}
              >
                <Download
                  rows={filter(summary)}
                  filename={pageConfig.heading}
                  headCells={headCells}
                  grandTotalColumns={pageConfig.grandTotalColumns}
                  emptyInfo={pageConfig.emptyInfo || 0}
                  emptyColumn={pageConfig.emptyColumn}
                  emptyCharacter={pageConfig.emptyCharacter || ""}
                />
              </div>
            </div>
            {snapshotGraph && (
              <ButtonGroup>
                <Button
                  onClick={() => handleButtonClick("Certification Snapshot")}
                  variant={
                    selectedButton === "Certification Snapshot"
                      ? "contained"
                      : "outlined"
                  }
                >
                  Certification Snapshot
                </Button>
                <Button
                  onClick={() => handleButtonClick("Progress and Projection")}
                  variant={
                    selectedButton === "Progress and Projection"
                      ? "contained"
                      : "outlined"
                  }
                >
                  Progress and Projection
                </Button>
              </ButtonGroup>
            )}

            <div className="small-container">
              <div style={{ marginTop: "2%" }}>
                {/* <LineChart />
                {showInfo && snapshot && (
                  <div className="section-info-wrapper mb-5">
                    <div className="sec-heading">
                      <span>
                        <ArrowRight
                          fontSize="large"
                          style={{ fill: "#BA1F00" }}
                        />
                      </span>
                      <span className="text">Snapshot</span>
                    </div>

                    {snapshot &&
                      Object.keys(snapshot).map((s) => {
                        return (
                          <React.Fragment key={s}>
                            <div
                              className="section-info"
                              style={{ paddingRight: "10%" }}
                            >
                              <div
                                className="line left"
                                style={{
                                  fontWeight: "normal",
                                  marginRight: "10%",
                                }}
                              >
                                {s}
                              </div>
                              <div className="line numbers">
                                <span
                                  className="bold big"
                                  style={{ padding: "10%" }}
                                >
                                  {snapshot[s]}
                                </span>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                  </div>
                )} */}
                {/* {
                  (console.log("labelsAboveBar", labels),
                  console.log("labelsAboveBar", datasets))
                } */}
                {props?.client && (
                  <FormControl className="select-form-control">
                    <Select
                      variant="outlined"
                      value={trainer}
                      onChange={(e) => {
                        const newClient = e.target.value;
                        updateClient(newClient);

                        const payload = {
                          view: "summary",
                          period,
                          collectionname,
                          accesscode,
                          collegename,
                          report,
                          client: newClient,
                        };
                        getMasterTrainer(payload, url);
                      }}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                    >
                      {/* {console.log("clientInSelect", client)} */}
                      {/* {console.log("clientDataInSelect", clientData)} */}
                      <MenuItem value="">{client}</MenuItem>
                      {clientData
                        ?.filter((data) => data !== client)
                        ?.map((s, index) => {
                          return (
                            <MenuItem key={index} value={s}>
                              {s}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                )}
                {labels && datasets && (
                  <BarChart labelData={labels} datasetData={datasets} />
                )}
                {/* {console.log("lineDatasets", lineDatasets)} */}
                {selectedButton === "Progress and Projection" ? (
                  <>
                    {snapshotGraph && snapshotGraph?.type === "Bar" ? (
                      <BarChart />
                    ) : (
                      <LineChart label={lineLabels} dataSet={lineDatasets} />
                    )}
                  </>
                ) : (
                  // <LineChart />
                  showInfo &&
                  snapshot &&
                  showInfoData && (
                    <div className="section-info-wrapper mb-5">
                      {/* Render your snapshot information here */}
                      <div className="sec-heading">
                        <span>
                          <ArrowRight
                            fontSize="large"
                            style={{ fill: "#BA1F00" }}
                          />
                        </span>
                        <span className="text">Snapshot</span>
                      </div>
                      {Object.keys(snapshot).map((s) => (
                        <div className="section-info" key={s}>
                          <div
                            className="line left"
                            style={{ fontWeight: "normal", marginRight: "10%" }}
                          >
                            {s}
                          </div>
                          <div className="line numbers">
                            <span
                              className="bold big"
                              style={{ padding: "10%" }}
                            >
                              {snapshot[s]}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  )
                )}
              </div>

              {labels && datasets && (
                <div className="parent">
                  <div className="left_red">
                    {pageConfig?.detailText ? (
                      <div className="right">
                        <Link
                          variant="contained"
                          color="primary"
                          to={viewAllArgs}
                          className="close-btn"
                        >
                          {pageConfig.detailText} <ChevronRight />
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}

              {pageConfig.tableTitle && (
                <div className="table-wrapper">
                  <div className="table-head-row">
                    <div className="left">
                      <ArrowRight
                        fontSize="large"
                        style={{ fill: "#BA1F00" }}
                      />
                      {pageConfig.tableTitle}
                    </div>
                    {pageConfig?.detailText ? (
                      <div className="right">
                        <Link
                          variant="contained"
                          color="primary"
                          to={viewAllArgs}
                          className="close-btn"
                        >
                          {pageConfig.detailText} <ChevronRight />
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {pageConfig?.Filter &&
                    pageConfig?.Filter.map((el) => {
                      if (el == "College Name") {
                        colleges.sort((a, b) => {
                          return a.localeCompare(b);
                        });
                        return (
                          colleges?.length > 0 && (
                            <div className="control">
                              <div className="s-label">College</div>
                              <div className="item-control">
                                <FormControl className="select-form-control">
                                  <Select
                                    variant="outlined"
                                    value={college}
                                    onChange={(e) => handleChange(e, "college")}
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                  >
                                    <MenuItem value="">All</MenuItem>
                                    {colleges.map((s, index) => {
                                      return (
                                        <MenuItem key={index} value={s}>
                                          {s}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                          )
                        );
                      }
                      if (el == "Faculty Name") {
                        return (
                          facultys?.length > 0 && (
                            <div className="control">
                              <div className="s-label">Faculty Name</div>
                              <div className="item-control">
                                <FormControl className="select-form-control">
                                  <Select
                                    variant="outlined"
                                    value={faculty}
                                    onChange={(e) => handleChange(e, "faculty")}
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                  >
                                    <MenuItem value="">All</MenuItem>
                                    {facultys.map((s, index) => {
                                      return (
                                        <MenuItem key={index} value={s}>
                                          {s}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                          )
                        );
                      }
                      if (el == "Division") {
                        return (
                          <div className="d-flex ml-1">
                            {divisions?.length > 0 && (
                              <div className="control ml-2">
                                <div className="s-label">Region</div>
                                <div className="item-control">
                                  <FormControl className="select-form-control">
                                    <Select
                                      variant="outlined"
                                      value={region}
                                      onChange={(e) =>
                                        handleChange(e, "region")
                                      }
                                      displayEmpty
                                      inputProps={{
                                        "aria-label": "Without label",
                                      }}
                                    >
                                      <MenuItem value="">All</MenuItem>
                                      {regions.map((s, index) => {
                                        return (
                                          <MenuItem key={index} value={s}>
                                            {s}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                            )}
                            <div>
                              <div className="control ml-2">
                                <div className="s-label">Division</div>
                                <div className="item-control">
                                  <FormControl className="select-form-control">
                                    <Select
                                      variant="outlined"
                                      value={division}
                                      onChange={(e) =>
                                        handleChange(e, "division")
                                      }
                                      displayEmpty
                                      inputProps={{
                                        "aria-label": "Without label",
                                      }}
                                    >
                                      <MenuItem value="">All</MenuItem>
                                      {divisions.map((s, index) => {
                                        return (
                                          <MenuItem key={index} value={s}>
                                            {s}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      if (el == "Batch") {
                        return (
                          batches?.length > 0 && (
                            <div className="control">
                              <div className="s-label">Batch</div>
                              <div className="item-control">
                                <FormControl className="select-form-control">
                                  <Select
                                    variant="outlined"
                                    value={batch}
                                    onChange={(e) => handleChange(e, "batch")}
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                  >
                                    <MenuItem value="">All</MenuItem>
                                    {batches?.map((s, index) => {
                                      return (
                                        <MenuItem key={index} value={s}>
                                          {s}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                          )
                        );
                      }
                      if (el == "District/Units") {
                        return (
                          districts?.length > 0 && (
                            <div className="control">
                              <div className="s-label">District</div>
                              <div className="item-control">
                                <FormControl className="select-form-control">
                                  <Select
                                    variant="outlined"
                                    value={district}
                                    onChange={(e) =>
                                      handleChange(e, "district")
                                    }
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                  >
                                    <MenuItem value="">All</MenuItem>
                                    {districts?.map((s, index) => {
                                      return (
                                        <MenuItem key={index} value={s}>
                                          {s}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                          )
                        );
                      }
                      if (el == "Police Station") {
                        return (
                          policeStations?.length > 0 && (
                            <div className="control">
                              <div className="s-label">Police Station</div>
                              <div className="item-control">
                                <FormControl className="select-form-control">
                                  <Select
                                    variant="outlined"
                                    value={policeStation}
                                    onChange={(e) =>
                                      handleChange(e, "policeStation")
                                    }
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                  >
                                    <MenuItem value="">All</MenuItem>
                                    {policeStations?.map((s, index) => {
                                      return (
                                        <MenuItem key={index} value={s}>
                                          {s}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                          )
                        );
                      }
                      if (el == "State") {
                        return (
                          states?.length > 0 && (
                            <div className="control">
                              <div className="s-label">State</div>
                              <div className="item-control">
                                <FormControl className="select-form-control">
                                  <Select
                                    variant="outlined"
                                    value={state}
                                    onChange={(e) => handleChange(e, "state")}
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                  >
                                    <MenuItem value="">All</MenuItem>
                                    {states?.map((s, index) => {
                                      return (
                                        <MenuItem key={index} value={s}>
                                          {s}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                          )
                        );
                      }
                      if (el == "Trainer") {
                        return (
                          trainers?.length > 0 && (
                            <div className="control">
                              <div className="s-label">Trainer</div>
                              <div className="item-control">
                                <FormControl className="select-form-control">
                                  <Select
                                    variant="outlined"
                                    value={trainer}
                                    onChange={(e) => handleChange(e, "Trainer")}
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                  >
                                    <MenuItem value="">All</MenuItem>
                                    {trainers?.map((s, index) => {
                                      return (
                                        <MenuItem key={index} value={s}>
                                          {s}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                          )
                        );
                      }
                    })}

                  {periods?.length > 0 && (
                    <div className="selection">
                      <div className="item-control">
                        <FormControl className="select-form-control">
                          <Select
                            variant="outlined"
                            value={period}
                            onChange={(e) => handleChange(e, "period")}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            {periods.map((p) => (
                              <MenuItem value={p.value}>{p.text}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  )}
                  <CustomTable
                    rows={filter(summary)}
                    headCells={headCells}
                    emptyColumn={pageConfig.emptyColumn}
                    emptyCharacter={pageConfig.emptyCharacter || ""}
                    defaultOrder={"desc"}
                    defaultOrderBy={"Total Participation"}
                    grandTotalColumns={pageConfig?.grandTotalColumns || []}
                    leftAlignColumn={["Super Trainer"]}
                    showRowsPerPage={pageConfig?.showRowsPerPage || 25}
                    showcolor1={pageConfig?.showColor1}
                    showColor={pageConfig?.showColor}
                    percentageColumn={pageConfig?.percentageColumn}
                    disableSortColumn={pageConfig?.disableSortColumn}
                    columnWidth={pageConfig?.columnWidth}
                    emptyInfo={pageConfig?.emptyInfo || 0}
                  />
                </div>
              )}

              <CustomModal open={open} handleClose={() => setOpen(!open)}>
                <div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(pageConfig.info),
                    }}
                    style={{ fontSize: "14px" }}
                  />

                  <div
                    style={{
                      margin: "25px 0",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      onClick={() => setOpen(!open)}
                      variant="contained"
                      style={{
                        borderRadius: "25px",
                        padding: "5px 25px",
                        backgroundColor: "#FFBC36",
                      }}
                    >
                      View Report
                    </Button>
                  </div>
                </div>
              </CustomModal>
            </div>
          </section>
        </div>
      );
    }
  }

  return <div>{retFun()}</div>;
}

export default MasterTrainer;
