import {
  FormControl,
  MenuItem,
  Select,
  Button,
  TextField,
  Input,
} from "@material-ui/core";
import { ArrowBack, Filter } from "@material-ui/icons";

import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { fetchMasterTrainer, fetchUserInfo } from "../../../services/auth";
import CustomTable from "../../partials/table";
import "./styles.scss";
import _ from "lodash";

import Download from "./Download";
import moment from "moment";
import useHash from "./useHash";
import { useClientContext } from "../../../store/ClientProvider";

const ViewDetails = ({ setShow, url, pageConfig }) => {
  const [collectionname, _setCollectionname] = useState(
    pageConfig?.collectionname
  );
  const history = useHistory();

  const tableRef = useRef();

  const [whatToShow, setWhatToShow] = useState(false);
  const [_heading, setHeading] = useState("");
  const [summary, setSummary] = useState([]);
  const [headCells, setHeadCells] = useState([]);

  const [zone, setZone] = useState("");
  const [zones, setZones] = useState([]);

  const [division, setDivision] = useState("");
  const [divisions, setDivisions] = useState([]);

  const [week, setWeek] = useState("");
  const [weeks, setWeeks] = useState([]);

  const [college, setCollege] = useState("");
  const [colleges, setColleges] = useState([]);

  const [region, setRegion] = useState("");
  const [regions, setRegions] = useState([]);

  const [faculty, setFaculty] = useState("");
  const [facultys, setFacultys] = useState([]);

  const [batch, setBatch] = useState("");
  const [batches, setBatches] = useState([]);

  const [certification, setCertification] = useState("");
  const [certifications, setCertifications] = useState([]);

  const [districts, setDistricts] = useState([]);
  const [district, setDistrict] = useState("");

  const [policeStation, setPoliceStation] = useState("");
  const [policeStations, setPoliceStations] = useState([]);

  const [superTrainer, setSuperTrainer] = useState("");
  const [superTrainers, setSuperTrainers] = useState([]);
  const [module, setModule] = useState("");
  const [modules, setModules] = useState([]);

  const [trainer, setTrainer] = useState("");

  // const [client, setClient] = useState("CSC");
  const { client, updateClient } = useClientContext();

  const [clientData] = useState(pageConfig?.client);

  const [showDialog, setShowDialog] = useState(false);
  const [startDate, setStartDate] = useState();

  const [endDate, setEndDate] = useState();
  const [accesscode, setAccesscode] = useState();
  const [collegename, setCollegename] = useState();
  const [report, setReport] = useState(pageConfig?.report);

  const [trianerText, setTrianerText] = useState("");
  const [trainers, setTrainers] = useState([]);

  const [batchState, setBatchState] = useState("");
  const [batchStates, setBatchStates] = useState([]);

  const [userType, setUserType] = useState("");
  const [userTypes, setUserTypes] = useState([]);

  const [state, setState] = useState("");
  const [states, setStates] = useState([]);

  const [parentFilter, setParentFilter] = useState("");

  const [childFilter, setChildFilter] = useState("");
  const [comboFilter, setComboFilter] = useState("");
  const [searchText, setSearchText] = useState("");
  const [hash, _setHash] = useHash();

  const organizationName = pageConfig?.organisationName;

  const getUserInfo = async () => {
    return fetchUserInfo().then((data) => {
      setAccesscode(data.data.accesscode);
      let col = data.data.meta?.bput?.college_code?.split(/[,]+/);
      let newCol = col?.[0]?.replace(/ /g, "+");
      setCollegename(newCol);
    });
  };
  useEffect(async () => {
    if (organizationName == "BPUT") {
      await getUserInfo();
    }
  }, []);

  useEffect(() => {
    setShow(false);
    if (organizationName == "BPUT" && collegename) {
      if (hash && collegename && accesscode) {
        setHeading(hash);
        const payload = {
          period: "This Week",
          view: "detail",
          collectionname,
          accesscode,
          collegename,
          report,
          client: client,
        };

        getMasterTrainer(payload, url);
      }
    } else {
      if (hash) {
        setHeading(hash);
        const payload = {
          period: "This Week",
          view: "detail",
          collectionname,
          accesscode,
          collegename,
          report,
          client: client,
        };

        getMasterTrainer(payload, url);
      }
    }
  }, [hash, collegename, accesscode, client]);

  const user = JSON.parse(localStorage.getItem("user"));

  const splitKey = (key) => {
    const _key = (user.organizationid = "6123add4dfd0fbe63095d7c7"
      ? key.split("  ")
      : key.split("("));

    const keys = {
      id: _key[0],
      label: _key[0].trim(),
      caption: _key[1] ? "(".concat(_key[1]) : null,
    };

    return keys;
  };

  const processHead = (arr, sum) => {
    // console.log("arrInProcesshead", arr);
    // console.log("arrInProcessheadsum", sum);
    let arr2 = Object.entries(sum).map(([key, val]) => {
      return Object.assign(
        {
          originalKey: key,
          numeric: !isNaN(val),
          disablePadding: false,
        },
        splitKey(key)
      );
    });
    // console.log("arr2InProcesshead", arr2);
    setHeadCells(arr2);
  };

  const getMasterTrainer = (payload, url) => {
    fetchMasterTrainer(payload, url)
      .then((data) => {
        // console.log("dataFromAPI", data.data);
        let _summary = data.data.data;

        // console.log(
        //   "summaryInFetchMaster",
        //   JSON.parse(JSON.stringify(_summary))
        // );
        _summary = JSON.parse(JSON.stringify(_summary));
        const _zones = _.uniq(_.map(_summary, (_s) => _s.Zone));
        const _divisions = _.uniq(_.map(_summary, (_s) => _s.Division));

        const _weeks = _.filter(
          _.uniq(_.map(_summary, (_s) => _s.Week)),
          (w) => w
        );
        const _batches = _.filter(
          _.uniq(_.map(_summary, (_s) => _s.Batch)),
          (b) => b
        );
        const _certifications = _.filter(
          _.uniq(_.map(_summary, (_s) => _s["Certified"])),
          (c) => c
        );
        const _facultys = _.filter(
          _.uniq(_.map(_summary, (_s) => _s["Faculty Name"])),
          (f) => f
        );
        const _regions = _.filter(
          _.uniq(_.map(_summary, (_s) => _s["Region"])),
          (f) => f
        );
        const _division1 = _.filter(
          _.uniq(_.map(_summary, (_s) => _s["Division"])),
          (f) => f
        );
        const _colleges = _.filter(
          _.uniq(_.map(_summary, (_s) => _s.College)),
          (d) => d
        );
        const _districts = _.filter(
          _.uniq(_.map(_summary, (_s) => _s["District/Units"])),
          (d) => d
        );

        const _policeStation = _.filter(
          _.uniq(_.map(_summary, (_s) => _s["Police Station"])),
          (d) => d
        );
        const _superTrainer = _.filter(
          _.uniq(_.map(_summary, (_s) => _s["Certification status"])),
          (d) => d
        );

        const _state = _.filter(
          _.uniq(_.map(_summary, (_s) => _s.State)),
          (d) => d
        );
        const _trainer = _.filter(
          _.uniq(_.map(_summary, (_s) => _s.Trainer)),
          (d) => d
        );
        const _userType = _.filter(
          _.uniq(_.map(_summary, (_s) => _s["User Type"])),
          (d) => d
        );
        const _batchState = _.filter(
          _.uniq(_.map(_summary, (_s) => _s["Batch State"])),
          (d) => d
        );
        const _module = _.filter(
          _.uniq(_.map(_summary, (_s) => _s.Module)),
          (d) => d
        );
        // console.log("_module", _module);
        setModules(_module);
        setUserTypes(_userType);
        setTrainers(_trainer);
        setBatchStates(_batchState);
        setStates(_state);
        setZones(_zones);
        setDivisions(_division1);

        setColleges(_colleges);
        setRegions(_regions);
        setFacultys(_facultys);

        setWeeks(_weeks);
        setBatches(_batches);
        setCertifications(_certifications);

        setDistricts(_districts);
        setPoliceStations(_policeStation);

        setSuperTrainers(_superTrainer);

        if (pageConfig?.dateFilter)
          _summary = _summary.map((_s) => ({
            ..._s,
            Date: _s?.Date && moment(_s?.Date).format("DD-MMM-YYYY"),
          }));

        setSummary(_summary);
        _summary?.[0] && processHead(Object.keys(_summary[0]), _summary[0]);
      })
      .catch((err) => {
        setWhatToShow(true);
        console.error(err);
      });
  };
  // console.log("modules", modules);

  const handleSearchTextChange = (e) => {
    const searchTerm = e.target.value;
    // console.log("searchTermInFunc", searchTerm);
    setSearchText(searchTerm?.toLowerCase());
    // if (searchTerm?.length > 0) {
    //   isSearch.current = true;
    //   const filteredResults = summary?.filter(
    //     (item) =>
    //       item["Batch State"]
    //         ?.toLowerCase()
    //         ?.includes(searchTerm?.toLowerCase()) ||
    //       item["Primary Coach"]
    //         ?.toLowerCase()
    //         ?.includes(searchTerm?.toLowerCase())
    //   );
    //   setSearchResults(filteredResults);
    // } else {
    //   setSearchResults(summary);
    //   isSearch.current = false;
    // }

    // if (searchTerm === "") {
    //   setSearchResults(summary);
    // }
  };

  const handleChange = (e, item) => {
    // console.log("item", item);
    const value = e.target.value;
    switch (item) {
      case "zone":
        setZone(value);
        break;
      case "division":
        setDivision(value);
        break;
      case "week":
        setWeek(value);
        break;
      case "faculty":
        setFaculty(value);
        break;
      case "college":
        setCollege(value);
        break;
      case "region":
        setRegion(value);
        break;
      case "batch":
        setBatch(value);
        break;
      case "certified":
        setCertification(value);
        break;
      case "district":
        setDistrict(value);
        break;
      case "policeStation":
        setPoliceStation(value);
        break;
      case "parentFilter":
        setParentFilter(value);
        break;
      case "childFilter":
        setChildFilter(value);
        break;
      case "state":
        setState(value);
        break;
      case "superTrainer":
        setSuperTrainer(value);
        break;
      case "trianerText":
        setTrianerText(value);
        break;
      case "batchState":
        setBatchState(value);
        break;
      case "userType":
        setUserType(value);
      case "module":
        setModule(value);
      default:
        break;
    }
  };

  const handleClose = () => {
    history.goBack();
  };

  const dateFilter = (row, date, operation) => {
    if (date) {
      if (operation == ">" && moment(row.Date) >= moment(date)) return true;
      else if (operation == "<" && moment(row.Date) <= moment(date))
        return true;
      else return false;
    } else return true;
  };
  const firstRow = summary[0] && Object.keys(summary[0]);
  console.log("firstRow", firstRow);

  const filter = (rows) => {
    let _filter = {
      Zone: zone === "" ? undefined : zone,
      Division: division === "" ? undefined : division,
      Region: region == "" ? undefined : region,
      College: college === "" ? undefined : college,
      Module: module === "" ? undefined : module,
      ["Faculty Name"]: faculty === "" ? undefined : faculty,
      Batch: batch === "" ? undefined : batch,
      ["Batch State"]: batchState === "" ? undefined : batchState,
      ["User Type"]: userType === "" ? undefined : userType,
      Trainer: trianerText === "" ? undefined : trianerText,

      Week: week === "" ? undefined : week,
      Certified: certification === "" ? undefined : certification,

      ["District/Units"]: district === "" ? undefined : district,
      ["Police Station"]: policeStation === "" ? undefined : policeStation,

      ["Certification status"]: superTrainer === "" ? undefined : superTrainer,

      [comboFilter]: trainer === "" ? undefined : trainer,

      State: state === "" ? undefined : state,

      [pageConfig?.comCouFilter?.parentFilter ||
      pageConfig?.coupleFilter?.parentFilter?.column]:
        parentFilter === "" ? undefined : parentFilter,
      [pageConfig?.coupleFilter?.childFilter?.column]:
        childFilter === "" ? undefined : childFilter,
    };

    _filter = _.omitBy(_filter, _.isUndefined);
    const filteredRows = _.filter(rows, _filter)
      .filter((r) => dateFilter(r, startDate, ">"))
      .filter((r) => dateFilter(r, endDate, "<"));
    // console.log("filteredRows", filteredRows);
    // console.log("firstRow in func", firstRow);
    if (searchText) {
      const searchResults = filteredRows?.filter((row) => {
        // for (const field of searchArray) {
        for (const field of firstRow) {
          if (row[field]?.toLowerCase()?.includes(searchText?.toLowerCase())) {
            return true;
          }
        }

        return false;
      });
      return searchResults;
    }
    return filteredRows;
    return _.filter(rows, _filter)
      .filter((r) => dateFilter(r, startDate, ">"))
      .filter((r) => dateFilter(r, endDate, "<"));
  };
  function comboFilter1(column, value) {
    let options = {};
    if (summary.length > 0)
      (summary || []).map((el) => {
        if (value.length > 0) {
          if (el["Coach-1 Name"].length > 0 && el[column] == value) {
            Object.assign(options, { [el["Coach-1 Name"]]: "Coach-1 Name" });
          }
          if (el["Coach-2 Name"].length > 0 && el[column] == value) {
            Object.assign(options, { [el["Coach-2 Name"]]: "Coach-2 Name" });
          }
        } else {
          if (el["Coach-1 Name"].length > 0) {
            Object.assign(options, { [el["Coach-1 Name"]]: "Coach-1 Name" });
          }
          if (el["Coach-2 Name"].length > 0) {
            Object.assign(options, { [el["Coach-2 Name"]]: "Coach-2 Name" });
          }
        }
      });

    return (
      Object.keys(options || {}).length > 0 && (
        <div className="control">
          <div className="s-label">Trainer</div>
          <div className="item-control">
            <FormControl className="select-form-control">
              <Select
                variant="outlined"
                value={trainer}
                onChange={(e) => {
                  setComboFilter(options[e.target.value]);
                  setTrainer(e.target.value);
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">All</MenuItem>
                {Object.keys(options || {}).map((s, index) => {
                  return (
                    <MenuItem key={index} value={s}>
                      {s}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>
      )
    );
  }

  function retFun() {
    return (
      <div>
        <section className="section view-details" style={{ margin: "0" }}>
          <div
            className="heading-row d-flex"
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <div
              className="d-flex"
              style={{ flexDirection: "column", width: "fit-content" }}
            >
              <div className="heading">
                {(pageConfig && pageConfig.heading) || ""}
              </div>
              <div className="sub-heading">
                {(pageConfig && pageConfig.subHeading) || ""}
              </div>
            </div>
            <div className="close-btn-l">
              <Button
                onClick={handleClose}
                className="close-btn"
                startIcon={<ArrowBack />}
              >
                Back
              </Button>
            </div>
            <Download
              rows={filter(summary)}
              filename={pageConfig?.heading || "Sample"}
              headCells={headCells}
              emptyInfo={pageConfig?.emptyInfo || 0}
            />
          </div>

          {/* {console.log("pageConfig", pageConfig?.showFilter)} */}
          {pageConfig?.showFilter && pageConfig?.showFilter?.length > 0 && (
            <div className="filters-title">Filters</div>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: "#f8f8f8",
            }}
          >
            <Input
              placeholder="Search..."
              style={{ margin: "2rem 0 0 2rem", paddingTop: "5px" }}
              value={searchText}
              onChange={handleSearchTextChange}
            />

            <div className="selection">
              {zones.length > 0 && false && (
                <div className="control">
                  <div className="s-label">Zone</div>
                  <div className="item-control">
                    <FormControl className="select-form-control">
                      <Select
                        variant="outlined"
                        value={zone}
                        onChange={(e) => handleChange(e, "zone")}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">All</MenuItem>
                        {zones.map((s, index) => {
                          return (
                            <MenuItem key={index} value={s}>
                              {s}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}
              {pageConfig?.coupleFilter &&
                Object.keys(pageConfig?.coupleFilter || {}).map((fil, i) => {
                  let filter = pageConfig?.coupleFilter?.[fil];
                  let prevKey = pageConfig.coupleFilter?.parentFilter.column;
                  let options =
                    i == 0
                      ? [
                          ...new Set(
                            (summary || []).map((ls) => ls[filter.column])
                          ),
                        ]
                      : [
                          ...new Set(
                            (summary || []).map((ls) =>
                              parentFilter.length > 0
                                ? ls[prevKey] == parentFilter
                                  ? ls[filter.column]
                                  : null
                                : ls[filter.column]
                            )
                          ),
                        ];
                  let value = i == 0 ? parentFilter : childFilter;
                  return (
                    options.length > 0 && (
                      <div className="control">
                        <div className="s-label">{filter.column}</div>
                        <div className="item-control">
                          <FormControl className="select-form-control">
                            <Select
                              variant="outlined"
                              value={value}
                              onChange={(e) => {
                                if (fil == "parentFilter") {
                                  setChildFilter("");
                                }
                                handleChange(e, fil);
                              }}
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem value="">All</MenuItem>
                              {options.map((s, index) => {
                                return (
                                  <MenuItem key={index} value={s}>
                                    {s}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    )
                  );
                })}
              {Object.keys(pageConfig?.comCouFilter || {}).map((el, i) => {
                let filter = pageConfig?.comCouFilter?.[el];
                let options =
                  i == 0
                    ? [...new Set((summary || []).map((ls) => ls[filter]))]
                    : [];
                let value = i == 0 ? parentFilter : "";
                if (el == "parentFilter")
                  return (
                    options.length > 0 && (
                      <div className="control">
                        <div className="s-label">{filter}</div>
                        <div className="item-control">
                          <FormControl className="select-form-control">
                            <Select
                              variant="outlined"
                              value={value}
                              onChange={(e) => {
                                if (el == "parentFilter") {
                                  handleChange(e, el);
                                  setTrainer("");
                                }
                              }}
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem value="">All</MenuItem>
                              {options.map((s, index) => {
                                return (
                                  <MenuItem key={index} value={s}>
                                    {s}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    )
                  );
                if (el == "comboFilter") {
                  return comboFilter1(
                    pageConfig?.comCouFilter?.parentFilter,
                    parentFilter
                  );
                }
              })}
              {/* {pageConfig?.client && pageConfig?.client.length > 0 && (
                <FormControl className="select-form-control">
                  <Select
                    variant="outlined"
                    value={trainer}
                    onChange={(e) => {
                      const newClient = e.target.value;
                      updateClient(newClient);
                      const payload = {
                        period: "This Week",
                        view: "detail",
                        collectionname,
                        accesscode,
                        collegename,
                        report,
                        client: newClient,
                      };
                      // setClient(e.target.value);
                      getMasterTrainer(payload, url);
                    }}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                  >
                    <MenuItem value="">All</MenuItem>
                    {clientData?.map((s, index) => {
                      return (
                        <MenuItem key={index} value={s}>
                          {s}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )} */}
              {console.log("pageConfig?.showFilter", pageConfig?.showFilter)}
              {pageConfig?.showFilter &&
                pageConfig?.showFilter.map((el) => {
                  if (el == "College Name") {
                    colleges.sort((a, b) => {
                      return a.localeCompare(b);
                    });
                    return (
                      colleges.length > 0 && (
                        <div className="control">
                          <div className="s-label">College</div>
                          <div className="item-control">
                            <FormControl className="select-form-control">
                              <Select
                                variant="outlined"
                                value={college}
                                onChange={(e) => handleChange(e, "college")}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="">All</MenuItem>
                                {colleges.map((s, index) => {
                                  return (
                                    <MenuItem key={index} value={s}>
                                      {s}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      )
                    );
                  }
                  if (el == "Module") {
                    // console.log("modules", modules);
                    modules.sort((a, b) => {
                      return a.localeCompare(b);
                    });
                    return (
                      modules?.length > 0 && (
                        <div className="control">
                          <div className="s-label">Module</div>
                          <div className="item-control">
                            <FormControl className="select-form-control">
                              <Select
                                variant="outlined"
                                value={college}
                                onChange={(e) => handleChange(e, "module")}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="">All</MenuItem>
                                {console.log("modules", modules)}
                                {modules?.map((s, index) => {
                                  return (
                                    <MenuItem key={index} value={s}>
                                      {s}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      )
                    );
                  }
                  if (el == "Region") {
                    return (
                      regions.length > 0 && (
                        <div className="control">
                          <div className="s-label">Region</div>
                          <div className="item-control">
                            <FormControl className="select-form-control">
                              <Select
                                variant="outlined"
                                value={region}
                                onChange={(e) => handleChange(e, "region")}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="">All</MenuItem>
                                {regions.map((s, index) => {
                                  return (
                                    <MenuItem key={index} value={s}>
                                      {s}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      )
                    );
                  }
                  if (el == "Faculty Name") {
                    return (
                      facultys.length > 0 && (
                        <div className="control">
                          <div className="s-label">Faculty Name</div>
                          <div className="item-control">
                            <FormControl className="select-form-control">
                              <Select
                                variant="outlined"
                                value={faculty}
                                onChange={(e) => handleChange(e, "faculty")}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="">All</MenuItem>
                                {facultys.map((s, index) => {
                                  return (
                                    <MenuItem key={index} value={s}>
                                      {s}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      )
                    );
                  }
                  if (el == "Division") {
                    divisions.sort((a, b) => {
                      return a.localeCompare(b);
                    });
                    return (
                      divisions.length > 0 && (
                        <div className="control">
                          <div className="s-label">Division</div>
                          <div className="item-control">
                            <FormControl className="select-form-control">
                              <Select
                                variant="outlined"
                                value={division}
                                onChange={(e) => handleChange(e, "division")}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="">All</MenuItem>
                                {divisions.map((s, index) => {
                                  return (
                                    <MenuItem key={index} value={s}>
                                      {s}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      )
                    );
                  }
                  if (el == "divisions") {
                    return (
                      divisions.length > 0 && (
                        <div className="control">
                          <div className="s-label">Division</div>
                          <div className="item-control">
                            <FormControl className="select-form-control">
                              <Select
                                variant="outlined"
                                value={division}
                                onChange={(e) => handleChange(e, "division")}
                                displayEmpty
                                // placeholder="All"
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="">All</MenuItem>
                                {divisions.map((s, index) => {
                                  return (
                                    <MenuItem key={index} value={s}>
                                      {s}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      )
                    );
                  }
                  // console.log("trainers", trainers);
                  // console.log("trianerText", trianerText);
                  if (el == "Trainer") {
                    return (
                      trainers?.length > 0 && (
                        <div className="control">
                          <div className="s-label">Trainer</div>
                          <div className="item-control">
                            <FormControl className="select-form-control">
                              <Select
                                variant="outlined"
                                value={trianerText}
                                onChange={(e) => handleChange(e, "trianerText")}
                                displayEmpty
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                              >
                                <MenuItem value="">All</MenuItem>
                                {trainers?.map((s, index) => {
                                  return (
                                    <MenuItem key={index} value={s}>
                                      {s}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      )
                    );
                  }
                  if (el == "Batch State") {
                    return (
                      batchStates?.length > 0 && (
                        <div className="control">
                          <div className="s-label">Batch State</div>
                          <div className="item-control">
                            <FormControl className="select-form-control">
                              <Select
                                variant="outlined"
                                value={batchState}
                                onChange={(e) => handleChange(e, "batchState")}
                                displayEmpty
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                              >
                                <MenuItem value="">All</MenuItem>
                                {batchStates?.map((s, index) => {
                                  return (
                                    <MenuItem key={index} value={s}>
                                      {s}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      )
                    );
                  }
                  if (el == "User Type") {
                    return (
                      userTypes?.length > 0 && (
                        <div className="control">
                          <div className="s-label">User Type</div>
                          <div className="item-control">
                            <FormControl className="select-form-control">
                              <Select
                                variant="outlined"
                                value={userType}
                                onChange={(e) => handleChange(e, "userType")}
                                displayEmpty
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                              >
                                <MenuItem value="">All</MenuItem>
                                {userTypes?.map((s, index) => {
                                  return (
                                    <MenuItem key={index} value={s}>
                                      {s}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      )
                    );
                  }
                  if (el == "weeks") {
                    return (
                      weeks.length > 0 && (
                        <div className="control">
                          <div className="s-label">Week</div>
                          <div className="item-control">
                            <FormControl className="select-form-control">
                              <Select
                                variant="outlined"
                                value={week}
                                onChange={(e) => handleChange(e, "week")}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="">All</MenuItem>
                                {weeks.map((s, index) => {
                                  return (
                                    <MenuItem key={index} value={s}>
                                      {s}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      )
                    );
                  }
                  if (el == "Batch") {
                    return (
                      batches.length > 0 && (
                        <div className="control">
                          <div className="s-label">Batch</div>
                          <div className="item-control">
                            <FormControl className="select-form-control">
                              <Select
                                variant="outlined"
                                value={batch}
                                onChange={(e) => handleChange(e, "batch")}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="">All</MenuItem>
                                {batches.map((s, index) => {
                                  return (
                                    <MenuItem key={index} value={s}>
                                      {s}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      )
                    );
                  }
                  if (el == "Police Station") {
                    return (
                      policeStations.length > 0 && (
                        <div className="control">
                          <div className="s-label">Police Station</div>
                          <div className="item-control">
                            <FormControl className="select-form-control">
                              <Select
                                variant="outlined"
                                value={policeStation}
                                onChange={(e) =>
                                  handleChange(e, "policeStation")
                                }
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="">All</MenuItem>
                                {policeStations.map((s, index) => {
                                  return (
                                    <MenuItem key={index} value={s}>
                                      {s}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      )
                    );
                  }
                  if (el == "District/Units") {
                    return (
                      districts.length > 0 && (
                        <div className="control">
                          <div className="s-label">District</div>
                          <div className="item-control">
                            <FormControl className="select-form-control">
                              <Select
                                variant="outlined"
                                value={district}
                                onChange={(e) => handleChange(e, "district")}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="">All</MenuItem>
                                {districts.map((s, index) => {
                                  return (
                                    <MenuItem key={index} value={s}>
                                      {s}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      )
                    );
                  }
                  if (el == "State") {
                    return (
                      states.length > 0 && (
                        <div className="control">
                          <div className="s-label">State</div>
                          <div className="item-control">
                            <FormControl className="select-form-control">
                              <Select
                                variant="outlined"
                                value={state}
                                onChange={(e) => {
                                  let arr = (summary || []).filter((ls) => {
                                    console.log("as", ls, el, e.target.value);
                                    return ls[el] == e.target.value ? ls : null;
                                  });
                                  console.log("arr", arr);
                                  handleChange(e, "state");
                                }}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="">All</MenuItem>
                                {states.map((s, index) => {
                                  return (
                                    <MenuItem key={index} value={s}>
                                      {s}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      )
                    );
                  }
                  if (el == "Certification status") {
                    return (
                      superTrainers.length > 0 && (
                        <div className="control">
                          <div className="s-label">Certification status</div>
                          <div className="item-control">
                            <FormControl className="select-form-control">
                              <Select
                                variant="outlined"
                                value={superTrainer}
                                onChange={(e) => {
                                  handleChange(e, "superTrainer");
                                }}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="">All</MenuItem>
                                {superTrainers.map((s, index) => {
                                  return (
                                    <MenuItem key={index} value={s}>
                                      {s}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      )
                    );
                  }
                })}
              {/* {pageConfig?.comboFilter?.length > 0 ?
                comboFilter1()
                : null
              } */}
              {certifications.length > 0 && (
                <div className="control">
                  <div className="s-label">Certification</div>
                  <div className="item-control">
                    <FormControl className="select-form-control">
                      <Select
                        variant="outlined"
                        value={certification}
                        onChange={(e) => handleChange(e, "certified")}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">All</MenuItem>
                        {certifications.map((s) => {
                          return <MenuItem value={s}>{s}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}

              {pageConfig?.dateFilter && (
                <div className="control">
                  <div className="item-control">
                    <TextField
                      id="date"
                      label="Start Date"
                      type="date"
                      value={startDate}
                      onChange={(event) => setStartDate(event.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
              )}

              {pageConfig?.dateFilter && (
                <div className="control">
                  <div className="item-control">
                    <TextField
                      id="date"
                      label="End Date"
                      type="date"
                      value={endDate}
                      onChange={(event) => setEndDate(event.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {(zone || division) && (
            <div className="caption">
              {`${division ? `${division} division` : ""}`}
            </div>
          )}
          {/* {console.log("summaryAboveTable", summary)} */}
          <CustomTable
            rows={filter(summary)}
            headCells={headCells}
            ignoreColumns={["Week", "userId", "report", "Program"]}
            ref={tableRef}
            id="table"
            emptyColumn={pageConfig?.emptyColumn}
            emptyCharacter={pageConfig?.emptyCharacter || ""}
            percentageColumn={pageConfig?.percentageColumn}
            clickable={["Full Name"]}
            open={showDialog}
            setOpen={setShowDialog}
            grandTotalColumns={pageConfig?.grandTotalColumns || []}
            showColor={pageConfig?.showColor}
            // showColor1={pageConfig?.showColor1}
            boldColumn={pageConfig?.boldColumn}
            disableSortColumn={pageConfig?.disableSortColumn}
            columnWidth={pageConfig?.columnWidth}
            emptyInfo={pageConfig?.emptyInfo || 0}
            htmlColumns={[
              "Options",
              "No of participants choosing the option",
              "% of participants choosing the option",
            ]}
          />
        </section>
      </div>
    );
  }

  return <div>{retFun()}</div>;
};

export default ViewDetails;
