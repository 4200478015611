import React, { useState, useEffect, useMemo } from "react";
import { Pie } from "react-chartjs-2";
import { Button } from "@material-ui/core";
import { toUrl } from "../../../../../utils/common";
import { Link } from "react-router-dom";

const PieChart = ({ widget, data, options, heading }) => {
  const getHeading = () => {
    return (
      <div className="w-100">
        <span className="font-18">
          {widget.heading ? widget.heading : heading}
        </span>
      </div>
    );
  };

  const viewAllArgs = useMemo(
    () => ({
      pathname: toUrl(
        `${window.location.pathname}/details/${widget.widgetName}#${heading}`
      ),
      state: { widget },
    }),
    [widget]
  );

  return (
    <div className="w-100 h-100 d-flex flex-column">
      <div className="row">{getHeading()}</div>
      <div className="h-100 d-flex align-items-center px-5 mx-5 chart-wrapper pie-chart-w">
        <Pie data={data} options={options} />
      </div>
      <div className="vd-btn-w">
        <Link variant="contained" color="primary" to={viewAllArgs}>
          View Details
        </Link>
      </div>
    </div>
  );
};

export default PieChart;
