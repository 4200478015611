import { commonTableProps, commonLineChartProps } from "./common";
export const boldFields = [];

export const NetCollegeRegistrationTransformer = ({ data, ...rest }) => {
  const colors = {
    Cummulative: "#CACACA",
    New: "#FFBC36",
  };
  return {
    ...commonLineChartProps,
    data: {
      labels: data?.map((row) => row.Month),
      datasets: ["Cumulative", "New"].map((field, index) => ({
        label: field,
        data: data?.map((row) => row[field]),
        backgroundColor: colors[field],
        borderColor: colors[field],
        yAxisID: index === 0 ? "y" : "y1",
        maxBarThickness: 50,
      })),
    },
    aggregations: rest?.aggregations
      ? Object.keys(rest.aggregations).map((field) => {
          return {
            text: field,
            value: rest?.aggregations[field],
          };
        })
      : [],
    // showViewAll: true,
    // viewAllLink: 'View all colleges',
    // heading: "All Colleges",
    viewAll: { show: true, text: "View all colleges", displayAs: "table" },
  };
};

export const NewCollegeRegistrationByDistrictTransformer = ({ data }) => {
  return {
    ...commonTableProps(data),
    filters: [
      {
        name: "A-Z",
        handler: () => ({ filter: "Actual" }),
        isSelected: (currentFilter) => currentFilter === "Actual",
      },
      {
        name: "Max. Coverage",
        handler: () => ({ filter: "Expected" }),
        isSelected: (currentFilter) => currentFilter === "Expected",
      },
      {
        name: "Least Coverage",
        handler: () => ({ filter: "Much less than expected" }),
        isSelected: (currentFilter) =>
          currentFilter === "Much less than expected",
      },
    ],
    heading: "District-wise College Registrations",
    viewAllLink: "View all districts",
  };
};

// export const NewSchoolRegistrationByStateTransformer = ({ data }) => {
// 	return {
// 		...commonTableProps(data),
// 		filters: [
// 			{
// 				name: 'A-Z',
// 				handler: () => ({ filter: 'Actual' }),
// 				isSelected: (currentFilter) => currentFilter === 'Actual',
// 			},
// 			{
// 				name: 'Max. Coverage',
// 				handler: () => ({ filter: 'Expected' }),
// 				isSelected: (currentFilter) => currentFilter === 'Expected',
// 			},
// 			{
// 				name: 'Least Coverage',
// 				handler: () => ({ filter: 'Much less than expected' }),
// 				isSelected: (currentFilter) => currentFilter === 'Much less than expected',
// 			}
// 		],
// 		viewAllLink: 'View all schools',
// 	};
// };
