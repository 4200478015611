import React, { useContext, useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import {
  Header,
  Sidebar,
  Dashboard,
  ViewAll,
  Footer,
  Auth,
  Snapshot,
  ViewDetails,
} from "./components";
import "./App.scss";
import { AppStateContext } from "./providers/app.provider";
import { toUrl } from "./utils/common";
import Analytics from "./components/analytics";
import Master from "./components/masterTrainer";
import { useLocation } from "react-router-dom";
import { ANALYTICS_URL } from "./constants/urls";
import FlashPointsAnalytics from "./components/dashboard/flashAnalytics";

const App = (props) => {
  const [state] = useContext(AppStateContext);
  const [show, setShow] = useState(true);
  const [url, setUrl] = useState(ANALYTICS_URL);
  const [pageConfig, setPageConfig] = useState(null);

  const location = useLocation();
  const wLocation = window.location;

  const key = location.hash;
  let _URL = ANALYTICS_URL;
  const menu = state?.menu || {};
  const menus = Object.values(menu);

  useEffect(() => {
    if (location.hash || wLocation.hash) {
      console.log("hash ==> ", location.hash, wLocation.hash);
      console.log("menu ==> ", menus);
      menus.forEach((reports) => {
        reports.forEach((r) => {
          const que = r.question;
          que.find((q) => {
            if (toUrl(q.que_text) === key.substring(1)) {
              _URL = _URL.concat(q.api);
              setUrl(_URL);
              setPageConfig(q);
            }
          });
        });
      });
    }
  }, [location, wLocation, state]);

  console.log("URL ==> ", url);

  const renderRedirect = () => {
    const categories = Object.keys(state.menu);

    if (categories.length > 0) {
      return (
        <Redirect
          exact
          from="/"
          to={toUrl(
            `/${categories[0]}/${state.menu[categories[0]][0].dashboardName}`
          )}
        />
      );
    }
    return <></>;
  };

  if (state.isLoggedIn) {
    return (
      <>
        {/* <Switch> */}
        <Header />
        <Sidebar show={show} />
        <div
          className="content-wrapper"
          style={{ padding: show ? "" : "6% 15px 15px 85px" }}
        >
          <Switch>
            <Redirect exact from="/login" to="/" />
            {renderRedirect()}
            <Route path="*/view-all/:widgetName" component={ViewAll} />
            <Route
              path="/flashpointanalytics"
              component={FlashPointsAnalytics}
            />
            <Route
              path="*/details/:widgetName"
              component={() => (
                <ViewDetails
                  show={show}
                  setShow={setShow}
                  url={url}
                  pageConfig={pageConfig}
                />
              )}
            />
            <Route path="/snapshot" component={Snapshot} />
            <Route path="/master" component={Master} />
            {/* <Route path="/analytics" component={Analytics} /> */}
            <Route
              path="*"
              component={() => (
                <Dashboard setShow={setShow} url={url} widget={pageConfig} />
              )}
            />
          </Switch>
        </div>
        <Footer />
        {/* </Switch> */}
      </>
    );
  }

  return <Auth />;
};

export default App;
