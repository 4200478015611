import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton, Modal } from "@material-ui/core";
import { Close } from "@material-ui/icons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: 14,
  boxShadow: 24,
  p: 4,
};

function CustomModal(props) {
  const { open, handleClose, children } = props;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
        {children}
      </Box>
    </Modal>
  );
}

CustomModal.propTypes = {};

export default CustomModal;
