import {
  FormControl,
  MenuItem,
  Select,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
} from "@material-ui/core";
import React, { useState } from "react";

import "./styles.scss";

import states, { years } from "./data";

const Snapshot = () => {
  const [state, setState] = useState("");
  const [school, setSchool] = useState("");
  const [schools, setSchools] = useState([]);
  const [year, setYear] = useState("");
  const [showSnapShot, setShowSnapShot] = useState(false);

  const handleChange = (e, item) => {
    const value = e.target.value;

    switch (item) {
      case "state":
        setState(value);

        const s = states.find((s) => s.name === value);
        if (s) {
          setSchools(s.schools);
        }
        break;
      case "school":
        setSchool(value);
        break;
      case "year":
        setYear(value);
        break;

      default:
        break;
    }
  };

  const columns = [
    {
      field: "firstName",
      headerName: "Teacher Name",
      width: 150,
      editable: true,
    },
    {
      field: "lastName",
      headerName: "Activity Status",
      width: 150,
      editable: true,
    },
    {
      field: "age",
      headerName: "Syllabus Completed",
      type: "number",
      width: 110,
      editable: true,
    },
    {
      field: "fullName",
      headerName: "Full name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
      valueGetter: (params) =>
        `${params.getValue(params.id, "firstName") || ""} ${
          params.getValue(params.id, "lastName") || ""
        }`,
    },
  ];

  const rows = [
    {
      id: 1,
      lastName: "Snow",
      firstName: "Jon",
      active: true,
      date: "20 Aug, 2021",
      syllabusCompleted: 9,
      total: 16,
      m1: 1,
      m2: 2,
      m3: 1,
      m4: 1,
      m5: 2,
      m6: 1,
      m7: null,
      m8: null,
    },
  ];

  return (
    <div className="content">
      <section className="section card">
        <div className="page-heading">School Snapshot</div>
        <div className="caption">Sample Report</div>
        <div className="row">
          <div className="col">
            <div className="item">
              <div className="item-label">State</div>
              <div className="item-control">
                <FormControl>
                  <Select
                    variant="outlined"
                    value={state}
                    onChange={(e) => handleChange(e, "state")}
                    displayEmpty
                    //   className={classes.selectEmpty}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {states.map((s) => {
                      return <MenuItem value={s.name}>{s.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="item">
              <div className="item-label">School</div>
              <div className="item-control">
                <FormControl>
                  <Select
                    variant="outlined"
                    value={school}
                    onChange={(e) => handleChange(e, "school")}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    disabled={!state}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {schools.map((s) => {
                      return <MenuItem value={s.name}>{s.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="item">
              <div className="item-label">Year</div>
              <div className="item-control">
                <FormControl>
                  <Select
                    variant="outlined"
                    value={year}
                    onChange={(e) => handleChange(e, "year")}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {years.map((y) => {
                      return <MenuItem value={y}>{y}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>

        {showSnapShot ? (
          <React.Fragment>
            <TableContainer className="snapshot-details">
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow className="b-row">
                    <TableCell className="t-head" rowSpan="2">
                      Teacher Name
                    </TableCell>
                    <TableCell className="t-head" rowSpan="2">
                      Activity Status
                    </TableCell>
                    <TableCell className="t-head" rowSpan="2">
                      Syllabus Completed
                    </TableCell>
                    <TableCell className="t-head b-td" colSpan="8">
                      Session Rhythm (Month Wise)
                    </TableCell>
                  </TableRow>
                  <TableRow className="b-row">
                    <TableCell className="t-head b-td">M1</TableCell>
                    <TableCell className="t-head b-td">M2</TableCell>
                    <TableCell className="t-head b-td">M3</TableCell>
                    <TableCell className="t-head b-td">M4</TableCell>
                    <TableCell className="t-head b-td">M5</TableCell>
                    <TableCell className="t-head b-td">M6</TableCell>
                    <TableCell className="t-head b-td">M7</TableCell>
                    <TableCell className="t-head b-td">M8</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name} className="b-row">
                      <TableCell component="th" scope="row">
                        {row.firstName}
                      </TableCell>
                      <TableCell>
                        <div className="active-cell">
                          <span
                            className={
                              row.active ? "active status" : "in-active status"
                            }
                          ></span>
                          {`${row.active ? "Active" : "Inactive"} (${
                            row.date
                          })`}
                        </div>
                      </TableCell>
                      <TableCell>
                        <span className="s-completed">
                          {row.syllabusCompleted}
                        </span>
                        /<span>{row.total}</span>
                      </TableCell>
                      <TableCell className="b-td">{row.m1}</TableCell>
                      <TableCell className="b-td">{row.m2}</TableCell>
                      <TableCell className="b-td">{row.m3}</TableCell>
                      <TableCell className="b-td">{row.m4}</TableCell>
                      <TableCell className="b-td">{row.m5}</TableCell>
                      <TableCell className="b-td">{row.m6}</TableCell>
                      <TableCell className="b-td">{row.m7}</TableCell>
                      <TableCell className="b-td">{row.m8}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="a-data">Awaiting Data</div>
          </React.Fragment>
        ) : (
          <div className="show-btn-w">
            <Button
              className="show-btn"
              color="primary"
              variant="contained"
              onClick={() => setShowSnapShot(true)}
            >
              Show Snapshot
            </Button>
          </div>
        )}
      </section>
    </div>
  );
};

export default Snapshot;
