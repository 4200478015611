export default [
  {
    name: "Karnataka",
    schools: [
      {
        name: "Army Public School",
      },
      {
        name: " Kendriya Vidyalaya",
      },
      {
        name: "DPS",
      },
      {
        name: "Navodaya",
      },
    ],
  },
  {
    name: "Maharashtra",
    schools: [
      {
        name: " Kendriya Vidyalaya",
      },
      {
        name: "DPS",
      },
    ],
  },
];

export const years = ["year 1", "year 2", "year 3"];

export const periods = ["Overall"];
