import React, { useEffect, useState } from "react";

import { widgetMap } from "./WidgetMap";
import { getWidgetData } from "../../../services/widget";
import Spinner from "../../partials/spinner";
import "./widget.scss";
import { isACP } from "../../../utils/common";

const WidgetWrapper = ({ widget, heading }) => {
  const [widgetState, setWidgetState] = useState({
    data: { data: [] },
    isloaded: false,
    error: false,
    params: {},
  });

  const getWidget = () => {
    if (!widgetState.isloaded) {
      return <Spinner />;
    }
    const config = widgetMap[widget.widgetName];
    if (!config) {
      return <></>;
    }
    if (widgetState.error) {
      return <></>;
    }

    return (
      <config.component
        widget={widget}
        {...config.transformer(widgetState.data)}
        loadData={loadData}
        params={widgetState.params}
        heading={heading}
      />
    );
  };

  const loadData = (params = {}) => {
    setWidgetState({ ...widgetState, isloaded: false, params });
    getWidgetData(widget.api, params)
      .then((resp) => {
        setWidgetState({
          data: resp.data,
          error: false,
          isloaded: true,
          params,
        });
      })
      .catch(() => {
        setWidgetState({
          data: { data: [] },
          error: true,
          isloaded: true,
          params,
        });
      });
  };

  useEffect(() => {
    if (!!widget.api) {
      const config = widgetMap[widget.widgetName];
      loadData(config?.transformer({ data: [] })?.initialParam || {});
    }
  }, [widget]);

  return (
    <div className={`${isACP ? "" : "w-100"} p-4 mt-3 widget $h-100`}>
      {getWidget()}
    </div>
  );
};

export default WidgetWrapper;
