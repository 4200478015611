import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import _ from "lodash";

import { AppStateContext } from "../../providers/app.provider";
import { capitalize, isACP, isAP } from "../../utils/common";
import { WidgetWrapper } from "./widgets";

import "./dashboard.scss";
import MasterTrainer from "../masterTrainer";
import ComingSoon from "../partials/comingSoon";

const Dashboard = ({ setShow, url, widget }) => {
  const [state] = useContext(AppStateContext);
  const [breadCrumb, setBreadCrumb] = useState([]);
  const [pageConfig, setPageConfig] = useState({});
  const location = useLocation();

  // const renderBreadCrumb = () => {
  //   if (isACP | isAP)
  //     return <span className="category-heading">{breadCrumb[0]} </span>;
  //   else
  //     return (
  //       <ul className="bread-crumb-list">
  //         {breadCrumb.map((crumb) => {
  //           return (
  //             <li key={crumb}>
  //               <span className="bread-crumb-item me-1 font-22">{crumb}</span>
  //             </li>
  //           );
  //         })}
  //       </ul>
  //     );
  // };

  const renderEmptyPage = () => {
    return (
      <React.Fragment>
        <div style={{ position: 'absolute', top: "30vh" }} className="welcome">
          <div className="line">Welcome to Illumine Analytics Dashboard</div>
          <div className="line">
            Please click on the left panel to access the relevant reports
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderPageComponent = () => {
    if (widget?.widgetName && !_.isEmpty(widget.widgetName)) {
      return (
        <MasterTrainer
          // showInfo={widget?.showInfo}
          // showFilters={widget?.showFilter}
          url={url}
          {...widget}
        />
      );
    } else {
      return <ComingSoon
        message={widget?.message} />;
    }
  };

  const renderPageDetail = () => {
    if (isACP | isAP)
      return location.hash === "" ? renderEmptyPage() : renderPageComponent();
    else if (breadCrumb.length > 1) {
      return (
        <span className="page-hint font-13">{pageConfig?.dashboardDetail}</span>
      );
    }
    return <></>;
  };

  const renderTiles = (widgetConfigs) => {
    let index = -1;
    return widgetConfigs.map((widgets) => {
      const tempWidget = [];
      for (let i = 0; i < widgets; i++) {
        tempWidget.push(1);
      }

      return tempWidget.map(() => {
        index++;
        return (
          <div
            key={pageConfig.widget[index].widgetName}
            className={`col-12 col-md-${12 / +widgets} mt-3`}
          >
            <WidgetWrapper widget={pageConfig.widget[index]} />
          </div>
        );
      });
    });
  };

  const renderWidgets = () => {
    if (isACP | isAP)
      return pageConfig?.question?.map((q, index) => {
        return (
          <section id={q.que_text.toLowerCase()} key={q.que_text}>
            {q?.widget?.widgetName ? (
              <>
                <div className="question">
                  {q.que_text} <br />
                  {q.que_desc}
                </div>
                <WidgetWrapper widget={q.widget} key={q.widget.widgetName} />
              </>
            ) : (
              ""
            )}
          </section>
        );
      });
    else {
      if (!pageConfig?.layoutSize || pageConfig?.layoutSize?.length === 0) {
        return <></>;
      }

      const widgetConfigs = [];
      pageConfig.layoutSize
        .map((layout) => layout.split("*"))
        .forEach((layouts) => {
          const configs = [];
          for (let i = 0; i < layouts[0]; i++) {
            configs.push(layouts[1]);
          }
          widgetConfigs.push(...configs);
        });

      return renderTiles(widgetConfigs);
    }
  };

  useEffect(() => {
    setShow(true);
    const breadCrumbs = location.pathname
      .split("/")
      .filter((path) => !!path)
      .map((text) => capitalize(text));
    if (breadCrumbs.length > 1) {
      setBreadCrumb(breadCrumbs);

      setPageConfig(
        state.menu[breadCrumbs[0]]?.find(
          (item) => item.dashboardName === breadCrumbs[1]
        )
      );
    }
  }, [location, state.menu]);

  // const scroll = () => {
  //   if (
  //     document.getElementById(
  //       location.hash.replace("#", "").replaceAll("+", " ")
  //     )
  //   )
  //     document
  //       .getElementById(location.hash.replace("#", "").replaceAll("+", " "))
  //       ?.scrollIntoView({ behavior: "smooth" });
  //   else
  //     setTimeout(() => {
  //       scroll();
  //     }, 500);
  // };
  // useEffect(() => {
  //   scroll();
  // }, [location]);

  return (
    <div className="container-fluid pe-2" style={{ margin: "auto" }}>
      <div className="row bread-crumb-wrapper">
        <div className="col-12">
          {/* {renderBreadCrumb()} */}
          {renderPageDetail()}
        </div>
      </div>
      <div className={isACP | isAP ? "column" : "row"}>{renderWidgets()}</div>
    </div>
  );
};

export default Dashboard;
