import React, { useState, useEffect, useMemo } from "react";
import { Line } from "react-chartjs-2";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Button } from "@material-ui/core";

import "./LineChart.scss";
import { Link, useLocation } from "react-router-dom";
import { toUrl } from "../../../../../utils/common";

const Chart = ({
  data,
  options,
  aggregations,
  filter,
  params,
  loadData,
  viewAll,
  widget,
  heading,
}) => {
  const initialValue = params.filter
    ? params.filter
    : filter?.values?.length > 0
    ? filter.values[0].value
    : "";
  const [filterValue, setFilterValue] = useState(initialValue);
  const location = useLocation();

  const getAggregation = () => {
    return aggregations.map((aggregation, index) => (
      <div className="col mb-3" key={index}>
        <div className="w-100 aggregation-text-wrapper">
          {aggregation &&
          aggregation.text &&
          !aggregation.text.includes("Total") ? (
            <span className="aggregation-text font-15">{aggregation.text}</span>
          ) : (
            <span className="aggregation-text font-15 total-aggregation">
              {aggregation.text}
            </span>
          )}
        </div>
        <div className="w-100">
          <span className="aggregation-value">
            {aggregation.text.includes("Total") ? (
              <span className="total-aggregation">{aggregation.value}</span>
            ) : (
              aggregation.value
            )}
            {index == 0 && +aggregation.value > 0 ? (
              <span className="material-icons arrow-up">forward</span>
            ) : (
              <></>
            )}
          </span>
        </div>
      </div>
    ));
  };

  const displayFilter = () => {
    if (!filter) return <></>;
    // return (<div className="filter-wrapper">
    return (
      <>
        <div
          className="align-items-start"
          style={{ display: "block", marginBottom: "10px" }}
        >
          {/* {viewAll?.show ? < a style={{ color: "#D09218" }} href="/view-all/NewCollegeRegistration">View all colleges</a> : ""} */}
          <Select
            onChange={(event) => {
              const value = filter.handler(event.target.value);
              setFilterValue(event.target.value);
              loadData(value);
            }}
            className="align-items-right"
            value={filterValue}
          >
            {filter.values.map((option, index) => (
              <MenuItem value={option.value} key={index}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </div>{" "}
      </>
    );
  };

  useEffect(() => {
    setFilterValue(initialValue, initialValue);
  }, [filter]);

  const viewAllArgs = useMemo(() => {
    return {
      pathname: toUrl(
        `${location.pathname}/details/${widget.widgetName}#${heading}`
      ),
      state: { widget },
    };
  }, [widget]);

  return (
    <div className="w-100 h-100 d-flex flex-column">
      {displayFilter()}
      <div className="row aggregation">{getAggregation()}</div>

      {/* <div className="h-100 d-flex align-items-end"> */}
      <div
        className="h-100 d-flex chart-wrapper"
        style={{ flexDirection: "column" }}
      >
        <Line data={data} options={options} />
      </div>
      <div className="vd-btn-w">
        <Link variant="contained" color="primary" to={viewAllArgs}>
          View Details
        </Link>
      </div>
    </div>
  );
};

export default Chart;
