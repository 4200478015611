import { commonTableProps } from './common';

export const StudentResourceUsageTransformer = ({ data, aggregation }) => {

	return {
		...commonTableProps(data),
		aggregation: {
			title: 'Total active students: ',
			value: aggregation
		},
		heading: 'Student Resources Usage',
		viewAllLink: 'View all resources',
	};
};

export const FacilitatorResourceUsageTransformer = ({ data, aggregation }) => {

	return {
		...commonTableProps(data),
		aggregation: {
			title: 'Total active teachers: ',
			value: aggregation
		},
		heading: 'Teacher Resources Usage',
		viewAllLink: 'View all resources',
	};
};