import React, { useContext } from 'react';

import { NetworkContext } from '../../../providers/network.provider';

import './NetworkHandler.scss'

const NetworkHandler = () => {
	const [state] = useContext(NetworkContext);

	if(state.loading)
		return (<div className="loader">
			<div style={{ padding: '24px' }}>
				<div className="spinner" />
			</div>
		</div>);

	return (<></>);
};

export default NetworkHandler;