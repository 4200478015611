import React from "react";
import Workbook from "react-excel-workbook";

const Export = ({ element, data, filename, SheetName, title }) => {
  return (
    <Workbook filename={filename} element={element}>
      <Workbook.Sheet data={data} name={SheetName}>
        {Object.keys(data[0]).map((col) => (
          <Workbook.Column label={col} value={col} />
        ))}
      </Workbook.Sheet>
    </Workbook>
  );
};

export default Export;
