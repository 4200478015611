import React, { useState, useEffect, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { toUrl } from "../../../../../utils/common";
import { Link, useLocation } from "react-router-dom";

const BarChart = ({
  data,
  options,
  filter,
  heading,
  loadData,
  params,
  widget,
}) => {
  const initialValue = params.filter
    ? params.filter.split("=")[0]
    : filter?.values?.length > 0
    ? filter.values[0].value
    : "";
  const [filterValue, setFilterValue] = useState(initialValue);
  const location = useLocation();

  const getFilter = () => {
    if (!filter) return <></>;
    return (
      <div className="col my-2">
        <div className="w-100">
          <Select
            onChange={(event) => {
              const value = filter.handler(event.target.value);
              setFilterValue(event.target.value);
              loadData(value);
            }}
            value={filterValue}
          >
            {filter.values.map((option, index) => (
              <MenuItem value={option.value} key={index}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setFilterValue(initialValue);
  }, [filter, initialValue]);

  const viewAllArgs = useMemo(() => {
    return {
      pathname: toUrl(
        `${location.pathname}/details/${widget.widgetName}#${heading}`
      ),
      state: { widget },
    };
  }, [widget]);

  return (
    <div className="w-100 h-100 d-flex flex-column">
      <span className="font-18">{heading}</span>
      <div className="row">{getFilter()}</div>
      <div className="h-100 d-flex align-items-end chart-wrapper">
        <Bar data={data} options={options} />
      </div>
      <div className="vd-btn-w">
        <Link variant="contained" color="primary" to={viewAllArgs}>
          View Details
        </Link>
      </div>
    </div>
  );
};

export default BarChart;
