import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import "./auth.scss";
import logo from "../../images/logo.svg";
import Login from "./login";
import Register from "./register";
import ForgotPassword from "./forgot-password";
import ResetPassword from "./reset-password";
import { isAP } from "../../utils/common";
import AP_Starmaker from "../../images/AP_Starmaker.svg";
import pedgog_text_logo from "../../images/pedgog_text.svg";
import illumine_logo from "../../images/illumine.svg";

const BPUT = () => {
  return (
    <>
      {/* <span className="d-block bold mt-3">Future Ready Contributor Program
		</span> */}
      <hr />
      {/* <div className="w-100 d-flex mt-5 align-items-center">
						<img className="bput me-2 img-responsive" src={biju} alt="logo"/>
						<h5 className="font-18">Biju Patnaik University of Technology</h5>
					</div> */}
      <span className="d-block bold mt-3">
        Pedgog Analytics Dashboard provides real-time insights to track the
        intervention outcomes during deployment.
      </span>
      <span className="d-block bold mt-3">
        Pedgog is a Digital Coaching Platform by Illumine.
      </span>
      {/* <hr />
      <span className="d-block bold mt-3">Brought to you by</span>
      <h6>
        <b>Illumine Foundation</b>
      </h6> */}
    </>
  );
};

const AP = () => {
  return (
    <>
      <h3 className="d-block bold mt-3 text-center">The Starmaker Program</h3>
      <img
        src={AP_Starmaker}
        style={{ width: "60%" }}
        className="d-flex w-60 ml-auto mr-auto"
      />
      <span className="d-block bold mt-3 text-center">
        The Analytics Dashboard provides real-time updates of progress of the
        program.
      </span>
    </>
  );
};

const Auth = () => {
  return (
    <div className="container vh-100">
      <div className="row vh-100 justify-content-center align-items-center">
        <div className="col-12 col-md-4">
          <div className="w-100 pe-5">
            <div className="w-100 d-flex align-items-center mt-3">
              <img
                className="logo me-2 img-responsive"
                src={pedgog_text_logo}
                alt="logo"
              />
              {/* <h3 className="mb-0">
                Analytics
                <br />
                Dashboard
              </h3> */}
            </div>
            {BPUT()}
          </div>
        </div>
        <div className="col-12 col-md-4">
          <Switch>
            <Route path="/register" component={Register} />
            <Route path="/login" component={Login} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password/:token" component={ResetPassword} />
            <Redirect to="/login" />
          </Switch>
        </div>
      </div>
      <div className="footer">
        <img src={illumine_logo} alt="Illumine" className="illumin-logo" />
        <div className="copyright">
          Copyright 2019-22; Illumine. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default Auth;
