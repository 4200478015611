import React, { createContext, useContext, useState } from "react";

const ClientContext = createContext();

export function ClientProvider({ children }) {
  const [client, setClient] = useState("CSC");

  const updateClient = (newClient) => {
    setClient(newClient);
  };
  return (
    <ClientContext.Provider value={{ client, updateClient }}>
      {children}
    </ClientContext.Provider>
  );
}
export function useClientContext() {
  return useContext(ClientContext);
}
