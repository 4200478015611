import React from "react";

import "./footer.scss";
import logo from "../../images/logo.svg";

const Footer = () => {
  return (
    <div className="w-100 footer mt-5 px-5 py-3">
      <div className="container-fluid font-12">
        <div className="row">
          <div className="col-12 col-md-6 py-4">
            <ul className="footer-items">
              <li>
                <a href="https://illumine.in/" target="_blank">
                  <span>
                    <img src={logo} className="img-fluid footer-image" />

                    <span>illumine.in</span>
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-6 py-4">
            <ul className="footer-items float-right">
              <li>
                <a
                  href="https://illumine.in/privacy-policy.html"
                  target="_blank"
                >
                  <span>Privacy Policy</span>
                </a>
              </li>
              <li>
                <a
                  href="https://illumine.in/cookie-policy.html"
                  target="_blank"
                >
                  <span>Cookie Policy</span>
                </a>
              </li>
              <li>
                <a href="https://illumine.in/terms-of-use.html" target="_blank">
                  <span>Terms & Conditions</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <div className="w-100 pt-3">
              <span>© 2021; Illumine. All Rights Reserved.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
