import React from 'react';

import './spinner.scss';

const Spinner = () => {
	return (<div className="spinner-loader">
		<div className="spinner" />
	</div>);
};

export default Spinner;