import {
  commonTableProps,
  BarChartOptions,
  StackedBarChartOptions,
  commonLineChartProps,
  MixedChartOptions,
  nonSortableFields,
  linkFields,
  PieChartOptions,
} from "./common";
import { getMonths, getLastMonth } from "../../../../utils/common";

export const NewSessionTransformer = ({ data, ...rest }) => {
  const colors = {
    Cummulative: "#CACACA",
    New: "#FFBC36",
  };
  return {
    ...commonLineChartProps,
    data: {
      labels: data.map((row) => row.Month),
      datasets: ["Cumulative", "New"].map((field, index) => ({
        label: field,
        data: data.map((row) => row[field]),
        backgroundColor: colors[field],
        borderColor: colors[field],
        yAxisID: index === 0 ? "y" : "y1",
      })),
    },
    aggregations: rest?.aggregations
      ? Object.keys(rest.aggregations).map((field) => {
          return {
            text: field,
            value: rest?.aggregations[field],
          };
        })
      : [],
  };
};

export const ModuleWiseSessionTransformer = ({ data }) => {
  return {
    ...commonTableProps(data),
    filters: [],
    heading: "Module-wise Sessions conducted",
    viewAllLink: "View all Modules",
  };
};

export const FacilatorWiseSessionPerformaceTransformer = ({ data }) => {
  return {
    columns:
      data.length > 0
        ? Object.keys(data[0]).map((row) => {
            return {
              field: row,
              headerName: row,
              sortable: !nonSortableFields.includes(row),
              link: {
                isLink: linkFields.includes(row) && data[0][row] !== "N/A",
                linkText: "View Report",
              },
            };
          })
        : [],
    rows: data,
    perPage: 10,
    filters: [
      {
        name: "All",
        handler: () => ({ filter: "All" }),
        isSelected: (currentFilter) => currentFilter === "All",
      },
      {
        name: "Low Preparation",
        handler: () => ({ filter: "Low Preparation" }),
        isSelected: (currentFilter) => currentFilter === "Low Preparation",
      },
      // {
      // 	name: 'On Track',
      // 	handler: () => ({ filter: 'On Track' }),
      // 	isSelected: (currentFilter) => currentFilter === 'On Track',
      // },
      // {
      // 	name: 'Lagging Behind',
      // 	handler: () => ({ filter: 'Lagging behind' }),
      // 	isSelected: (currentFilter) => currentFilter === 'Lagging behind',
      // },
      // {
      // 	name: 'Best Faculty',
      // 	handler: () => ({ filter: 'Best Faculty' }),
      // 	isSelected: (currentFilter) => currentFilter === 'Best Faculty',
      // }
    ],
    heading: "Facilitator-wise Session Performance",
    viewAllLink: "View all faculty",
  };
};

export const ExpectedActualSessions = ({ data }) => {
  const colors = {
    Expected: "#CACACA",
    Actual: "#FFBC36",
  };

  return {
    data: {
      labels: data.map((row) => row["week"]),
      datasets: ["Expected", "Actual"].map((field) => ({
        label: field,
        data: data.map((row) => row[field]),
        backgroundColor: colors[field],
      })),
    },
    options: BarChartOptions,
    filter: {
      values: getMonths(8),
      handler: (month) => {
        return { filter: `Month-Year=${month}` };
      },
    },
    heading: "Sessions – Expected vs. Actual",
    initialParam: {
      filter: `Month-Year=${getLastMonth()}`,
    },
  };
};

export const FacilatorPreparationSessions = ({ data }) => {
  const colors = {
    Expected: "#CACACA",
    Current: "#FFBC36",
  };
  return {
    data: {
      labels: data.map((row) => row["Modules"]),
      datasets: [
        {
          label: "Prepared",
          data: data.map((row) => row["% Prepared"]),
          backgroundColor: colors.Current,
        },
        {
          label: "Not prepared",
          data: data.map((row) => 100 - row["% Prepared"]),
          backgroundColor: colors.Expected,
        },
      ],
    },
    options: {
      ...StackedBarChartOptions,
      plugins: {
        tooltip: {
          callbacks: {
            title: function (tooltipItem, tooltipData) {
              try {
                return data.find(
                  (row) => row["Modules"] == tooltipItem[0]["label"]
                )["Module Name"];
              } catch (error) {
                return null;
              }
            },
          },
        },
      },
    },
    heading: "Preparation of Facilitators (Module-wise)",
  };
};

export const EngagementIndexTransformer = ({ data }) => {
  const colors = {
    Expected: "#CACACA",
    PreparationPercentage: "#FFBC36",
  };

  data = data.sort((a, b) => a.Distribution - b.Distribution);

  return {
    data: {
      labels: data.map((row) => Math.floor(row.Distribution)),
      datasets: [
        {
          type: "scatter",
          borderColor: "rgb(0,0,255)",
          borderWidth: 6,
          data: data.map((row) => ({
            x: Math.floor(row.Distribution),
            y: Math.floor(row.Value),
          })),
          pointRadius: 3,
        },
        {
          type: "bar",
          backgroundColor: "blue",
          data: data.map((row) => Math.floor(row.Value)),
          borderColor: "white",
          borderWidth: 1,
          barPercentage: 0.4,
        },
      ],
    },
    options: MixedChartOptions,
    heading: "Distribution of sessions on Engagement Index",
    description:
      "Engagement Index is based on the deviation from an “Ideal Session Footprint”. It is a function of user journey, time spent on each milestones. We will compare this score with the score given by auditors to improve the algorithm through machine learning.",
  };
};

export const PreferedLanguageTransformer = ({ data }) => {
  const colors = ["#949820", "#FFBC36"];
  return {
    ...PieChartOptions,
    data: {
      labels: data.map((row) => row["language"]),
      datasets: [
        {
          label: "Language Prefered",
          data: data.map((row) => row["percentage"]),
          backgroundColor: colors,
          borderColor: colors,
          borderWidth: 1,
        },
      ],
    },
    heading: "Proportion of Hindi & English Sessions Conducted",
  };
};
