import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { widgetMap } from "../widgets/WidgetMap";
import { getWidgetData } from "../../../services/widget";
import Spinner from "../../partials/spinner";

const ViewAll = () => {
  const {
    state: { widget },
  } = useLocation();

  const [widgetState, setWidgetState] = useState({
    data: [],
    isloaded: false,
    error: false,
    params: {},
  });

  const getWidget = () => {
    if (!widgetState.isloaded) {
      return <Spinner />;
    }
    const config = widgetMap[widget.widgetName];
    if (!config) {
      return <></>;
    }

    return (
      <config.component
        widget={widget}
        {...config.transformer(widgetState.data)}
        showPagination
        perPage={20}
        loadData={loadData}
        params={widgetState.params}
      />
    );
  };

  const loadData = (params = {}) => {
    setWidgetState({ ...widgetState, isloaded: false, params });
    getWidgetData(widget.api, params)
      .then(({ data }) => {
        setWidgetState({ data, error: false, isloaded: true, params });
      })
      .catch(() => {
        setWidgetState({
          data: { data: [] },
          error: true,
          isloaded: true,
          params,
        });
      });
  };

  useEffect(() => {
    if (!!widget.api) {
      const config = widgetMap[widget.widgetName];
      loadData(config?.transformer({ data: [] })?.initialParam || {});
    }
  }, [widget]);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // });

  return (
    <div className="container-fluid pe-2 pe-md-5">
      <div className="row">
        <div className="col-12">
          <div className="w-100 h-100 p-4 mt-3 card widget">{getWidget()}</div>
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
