import React, { useState, useMemo } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { Link } from "react-router-dom";
import { toUrl } from "../../../../../utils/common";
import moment from "moment";
import "./Table.scss";
import { dateField } from "../../transformers/facilitors";

const descendingComparator = (a, b, orderBy) => {
  let value1 = a[orderBy],
    value2 = b[orderBy];
  if (typeof a[orderBy] === "string") {
    value1 = `${a[orderBy]}`.toLowerCase();
    value2 = `${b[orderBy]}`.toLowerCase();
  }

  if (value2 < value1) {
    return -1;
  }
  if (value2 > value1) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  if (order === "") {
    return (a, b) => 0;
  }
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    return comparator(a[0], b[0]);
  });
  return stabilizedThis.map((el) => el[0]);
};

const TableWidget = ({
  rows,
  columns,
  filters,
  heading,
  perPage,
  showPagination = false,
  widget,
  viewAllLink,
  aggregation,
  loadData,
  params,
  boldFields,
}) => {
  const initialFilter = params?.filter || "";
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(perPage);

  const viewAllArgs = useMemo(
    () => ({
      pathname: toUrl(
        `${window.location.pathname}/view-all/${widget.widgetName}#${heading}`
      ),
      state: { widget },
    }),
    [widget]
  );

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const renderSort = (item) => {
    return (
      <span
        onClick={() => {
          loadData(item.handler());
        }}
        className={`badge ms-2 ${item.isSelected(initialFilter) && "selected"}`}
        key={item.name}
      >
        {item.name}
      </span>
    );
  };

  const renderFilter = () => {
    if (!filters?.length) return <></>;

    return <div className="w-100 mt-3">{filters.map(renderSort)}</div>;
  };

  const renderAggregation = () => {
    if (!aggregation) return <></>;

    return (
      <div className="w-100 mt-3">
        <span>
          {aggregation.title}
          {aggregation.value}
        </span>
      </div>
    );
  };

  const getTitle = () => {
    return (
      <div className="col">
        <div className="w-100">
          <span className="aggregation-text font-18">
            {widget.heading ? widget.heading : heading}
          </span>
        </div>
        {showPagination ? (
          <div>
            {renderAggregation()}
            {renderFilter()}{" "}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderPagination = () => {
    if (showPagination) {
      return (
        <TablePagination
          rowsPerPageOptions={[10, 20, 25]}
          component="div"
          count={rows?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      );
    }

    return <></>;
  };

  return (
    <div className="w-100 d-flex flex-column">
      <div className="row">{getTitle()}</div>
      {renderPagination()}
      <div className="d-flex align-items-end chart-wrapper">
        <TableContainer>
          <Table
            className="table"
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                {columns.map((column, index) => {
                  return column.sortable ? (
                    <TableCell
                      key={column.field}
                      align={isNaN(rows[0][column.field]) ? "left" : "center"}
                      style={{ paddingLeft: 0 }}
                      sortDirection={orderBy === column.field ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === column.field}
                        direction={orderBy === column.field ? order : "asc"}
                        onClick={createSortHandler(column.field)}
                      >
                        {column.headerName}
                        {orderBy === column.field ? (
                          <span className="visibility-none">
                            {order !== ""
                              ? order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"
                              : ""}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ) : (
                    <TableCell align="left">{column.headerName}</TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      {Object.keys(row).map((key, i) =>
                        boldFields?.includes(columns[i].field) ? (
                          <TableCell
                            key={key}
                            align={isNaN(row[key]) ? "left" : "center"}
                            style={{ paddingLeft: 0, fontWeight: "bold" }}
                          >
                            {columns[i]?.link?.isLink ? (
                              <a href={row[key]} target="_blank">
                                <button className="btn btn-primary font-12">
                                  {columns[i]?.link?.linkText}
                                </button>
                              </a>
                            ) : (
                              <span className="table-row-text" title={row[key]}>
                                {row[key]}
                              </span>
                            )}
                          </TableCell>
                        ) : (
                          <TableCell
                            key={key}
                            align={isNaN(row[key]) ? "left" : "center"}
                            style={{ paddingLeft: 0 }}
                          >
                            {columns[i]?.link?.isLink ? (
                              <a href={row[key]} target="_blank">
                                <button className="btn btn-primary font-12">
                                  {columns[i]?.link?.linkText}
                                </button>
                              </a>
                            ) : dateField?.includes(columns[i].field) ? (
                              <span
                                className="table-row-text"
                                title={moment(row[key]).format("DD/MM/YYYY")}
                              >
                                {moment(row[key]).format("DD/MM/YYYY")}
                              </span>
                            ) : (
                              <span className="table-row-text" title={row[key]}>
                                {row[key]}
                              </span>
                            )}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {!showPagination && viewAllLink ? (
        <div className="w-100 d-flex align-items-end flex-column vd-btn-w">
          <Link className="mt-3" to={viewAllArgs}>
            <span className="menu-name">
              {widget.viewAllLink ? widget.viewAllLink : viewAllLink}{" "}
              {/* <span className="material-icons black">arrow_forward</span> */}
            </span>
          </Link>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TableWidget;
