import React from "react"
import { Box, Tab as MuiTab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs as MuiTabs, Typography } from "@material-ui/core"
import { withStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';
import { Bar } from "react-chartjs-2";

const Tabs = withStyles({
    flexContainer: { display: 'block' },
    indicator: { backgroundColor: "black" }
})(MuiTabs)

const Tab = withStyles({

})(MuiTab)

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Leaderboard = [
    { "StudentId": "6135cd63715db90031f2f1f1", "Associate Name": "LEARNER2", "Learning Hour": 1, "Score": 1 },
    { "StudentId": "6135ee2fd1cb11003035cae2", "Associate Name": "Learner Five", "Learning Hour": 1, "Score": 1 },
    { "StudentId": "6135f0a0d1cb11003035cb03", "Associate Name": "Learner Seven", "Learning Hour": 1, "Score": 0 },
    { "StudentId": "6135f199d1cb11003035cb0c", "Associate Name": "Subramaniam", "Learning Hour": 1, "Score": 1 },
    { "StudentId": "6135f35cd1cb11003035cb32", "Associate Name": "Learner Nine", "Learning Hour": 1, "Score": 0 },
    { "StudentId": "613601d8d1cb11003035cb6c", "Associate Name": "Ashok", "Learning Hour": 1, "Score": 20 },
    { "StudentId": "61360c0ed1cb11003035cbed", "Associate Name": "Rani", "Learning Hour": 1, "Score": 1 },
    { "StudentId": "61360c4cd1cb11003035cbf8", "Associate Name": "Subramaniam", "Learning Hour": 1, "Score": 2 },
    { "StudentId": "61360d21d1cb11003035cc02", "Associate Name": "Advait Srinivas", "Learning Hour": 1, "Score": 10 },
    { "StudentId": "613610f8d1cb11003035cc0b", "Associate Name": "Palak", "Learning Hour": 1, "Score": 1 },
]

export default () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return <>
        <Tabs indicatorColor="primary"
            value={value}
            onChange={handleChange}
            centered>
            <Tab label="Performance Tracker" {...a11yProps(0)} />
            <Tab label="Leaderboard" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
            <Bar data={{
                labels: ["Africa", "Asia", "Europe", "Latin America", "North America"],
                datasets: [
                    {
                        label: "Population (millions)",
                        backgroundColor: ["#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9", "#c45850"],
                        data: [2478, 5267, 734, 784, 433]
                    }
                ]
            }} options={{
                legend: { display: false },
                title: {
                    display: true,
                    text: 'Predicted world population (millions) in 2050'
                },
                indexAxis: 'y',
            }} />
        </TabPanel>
        <TabPanel value={value} index={1}>
            <div>
                <div>Question 1</div>
                <TableContainer>
                    <Table>
                        <TableHead>
                            {Object.keys(Leaderboard[0]).map((l) => <TableCell>{l}</TableCell>)}
                        </TableHead>
                        <TableBody>
                            {Leaderboard.map(l => <TableRow>
                                {Object.values(l).map((l1) => <TableCell>{l1}</TableCell>)}
                            </TableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div style={{ marginTop: "5%" }}>
                <div>Question 1</div>
                <TableContainer>
                    <Table>
                        <TableHead>
                            {Object.keys(Leaderboard[0]).map((l) => <TableCell>{l}</TableCell>)}
                        </TableHead>
                        <TableBody>
                            {Leaderboard.map(l => <TableRow>
                                {Object.values(l).map((l1) => <TableCell>{l1}</TableCell>)}
                            </TableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </TabPanel>
    </>
}